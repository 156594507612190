import React from 'react'

const Loading = () => {
    return (
        <div>
        <section className="row">
          <div className="col-xl-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">LOADING</h4>

                <p className="placeholder-glow">
                  <span className="placeholder col-12" />
                </p>
                <p className="placeholder-wave mb-0">
                  <span className="placeholder col-12" />
                </p>
              </div>{" "}
              {/* end card-body*/}
            </div>{" "}
            {/* end card*/}
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">LOADING</h4>

                <p className="placeholder-glow">
                  <span className="placeholder col-12" />
                </p>
                <p className="placeholder-wave mb-0">
                  <span className="placeholder col-12" />
                </p>
              </div>{" "}
              {/* end card-body*/}
            </div>{" "}
            {/* end card*/}
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">LOADING</h4>

                <p className="placeholder-glow">
                  <span className="placeholder col-12" />
                </p>
                <p className="placeholder-wave mb-0">
                  <span className="placeholder col-12" />
                </p>
              </div>{" "}
              {/* end card-body*/}
            </div>{" "}
            {/* end card*/}
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">LOADING</h4>

                <p className="placeholder-glow">
                  <span className="placeholder col-12" />
                </p>
                <p className="placeholder-wave mb-0">
                  <span className="placeholder col-12" />
                </p>
              </div>{" "}
              {/* end card-body*/}
            </div>{" "}
            {/* end card*/}
          </div>
        </section>
      </div>
    )
}

export default Loading
