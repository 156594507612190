import React,{useState,useEffect, Fragment} from 'react'
import { updateCcharge} from '../../libs/ccharge'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import { filesSearch } from  "../../libs/files";
import FilesList from '../../components/FilesList';
import {SearchCC} from '../../libs/ccharge'
import {SearchEnt} from '../../libs/entreprise'
import Loading from '../../components/Loading';


const MDetail = (data) => {
  const [entreprises, setEntreprises] = useState([]);
  const [ccharge, setCcharge] = useState([]);
  const [inputs, setInputs] = useState(data.post);
  const [files, setFiles] = useState([]);
  const [postid, setPostid] = useState("");
  const [showup, setShowup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showfiles, setShowFiles] = useState([]);
  const [loading, setloading] = useState(false);


  const notify = (msg,type)=>store.addNotification({
    title: "succès",
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  }); 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    console.log(inputs)
  }
  const handleSubmit = (event) => {
    event.preventDefault();
  
    create(inputs)

  }
  const create = async(post)=>{
    try {

      const author_id = localStorage.getItem("id")
      const graphqlQuery = {
        query: updateCcharge,
        variables: {
        id: post.id,
        NetIntituledelOperation: post.NetIntituledelOperation,
        Intituleduprojet: post.Intituleduprojet,
        NduLot: post.NduLot,
        IntituleduLot: post.IntituleduLot,
        DatedeNotification: post.DatedeNotification,
        Datedinscription: post.Datedinscription,
        APInitiale: post.APInitiale,
        APactuelle: post.APactuelle,
        Cahierdescharges: post.Cahierdescharges,
        DatedepotCMW: post.DatedepotCMW,
        DatedeprogrammationCMW: post.DatedeprogrammationCMW,
        Rapporteur: post.Rapporteur,
        NdeVisaCMW: post.NdeVisaCMW,
        DatedeVisaCMW: post.DatedeVisaCMW,
        typedepublication: post.typedepublication,
        Ndepublication: post.Ndepublication,
        Datedepublication: post.Datedepublication,
        JournaldePublication: post.JournaldePublication,
        DatedePublicationsurJournal: post.DatedePublicationsurJournal,
        Dureedepreparationdesoffres: post.Dureedepreparationdesoffres,
        Ndouverture: post.Ndouverture,
        Datedouverture: post.Datedouverture,
        Ndevaluationdesoffres: post.Ndevaluationdesoffres,
        Datedevaluationdesoffres: post.Datedevaluationdesoffres,
        Ndelettredecommande: post.Ndelettredecommande,
        Datedelettredecommande: post.Datedelettredecommande,
        Datedattribution: post.Datedattribution,
        Observation: post.Observation,
        author_id: post.author.id,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          console.log(data)
          notify("Creation Cahier de charge "+ data.data.updateCcharge.NetIntituledelOperation +" avec succés","success")
          setPostid(data.data.updateCcharge.id)
          setShowup(true)
        })
        .catch((error) => {
          notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
        })

        
    } catch {
         
    }

    

    

  }
  useEffect(() => {
    
    handleLoadF(data.post.id);
    try {
      setloading(true)
      const key = " "
      const graphqlQuery = {
        query: SearchCC,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.CcSearch;
          
          setCcharge(data);
          setloading(false)
        });
    } catch {}
    try {
      setloading(true)
      const key = ""
      const graphqlQuery = {
        query: SearchEnt,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.EntSearch;
          setEntreprises(data);
          setloading(false)
        });
    } catch {}
  }, []);
  const handleLoadF = (id) => {
    const graphqlQuery = {
      query: filesSearch,
      variables: {
        column_name: "marche_id",
        id: id,
      },
    };
    try {
  
      
      
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.FilesSearch;
          console.log("files:"+ data)
          setShowFiles(data);
         
        })
        .catch((error) => {
          
        })
  
        
    } catch {
         
    }
  };
 
  const handleFiles = (event) => {
    event.preventDefault();
   
    filesPost(files)
  }
  const filesPost= async (files) => {
    setUploading(true)
    var myHeaders = new Headers();
    const author_id = localStorage.getItem("id")
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("ggmpToken"));
    
    var formdata = new FormData();
    formdata.append("operations", "{\n        \"query\": \"mutation Files($files: Upload!,$author_id:String!,$ccharge_id:String!) { Files(files: $files,author_id:$author_id,ccharge_id:$ccharge_id) }\",\n        \"variables\": {\n  \"files\": null,\n\"author_id\":\""+author_id+"\"\n ,\n\"ccharge_id\":\""+postid+"\"\n        }\n    }");
    formdata.append("map", "{\n        \"0\": [\"variables.files\"]}");
    formdata.append("0", files);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(API, requestOptions)
      .then(response => response.text())
      .then((result) => {
        notify("fichier envoyé avec succès","success")
        handleLoadF(postid)
        setUploading(false)
    })
      .catch((error) => { notify("Erreur: verifier votre connexion","danger")
      setUploading(false)
    });
  

  };
  
    return (
      <div className="content-page">
  <div className="content">
    {/* Start Content*/}
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                <li className="breadcrumb-item"><a href="javascript: void(0);">Marché</a></li>
                <li className="breadcrumb-item active">Modification</li>
              </ol>
            </div>
            <button type="button" className="btn btn-default waves-effect waves-light" onClick={data.onReset}>
  <span className="btn-label"><i className="mdi mdi-arrow-left-bold-outline
" /></span>Retour
</button>

            <h4 className="page-title">Modification de Marché</h4>
          </div>
        </div>
      </div>    
      {/* end page title */} 
      {!loading &&
      <div className="row">
        <div className="col-12">
          <div className="card">
          <fieldset disabled="disabled">
          <form onSubmit={handleSubmit} >
            <div className="card-body">
              <div className="row">
                <div className="col-xl-6">
                  <div className="mb-3">
                  <label htmlFor="project-overview" className="form-label">Cahier des Charge</label><input value={inputs.ccharge.NetIntituledelOperation }  className="form-label" disabled />
                    <select 
                    name="ccharge_id" 
                    value={inputs.ccharge_id || "null"} 
                    onChange={handleChange}
                     className="form-control" data-toggle="select2" data-width="100%">
                    <Fragment>
{ccharge.map((post) => (
 <option key={post.id} value={post.id}>{post.NetIntituledelOperation}</option>
 
))}
</Fragment>
                      
                    </select>
                  </div>
                  <div className="mb-3">
                  
                    <label htmlFor="projectname" className="form-label">N° et Intitulé de l'Opération</label>
                    <input type="text"  className="form-control" placeholder="N° et Intitulé de l'Opération" 
                    name="NetIntituledelOperation" 
                    value={inputs.NetIntituledelOperation || "null"} 
                    onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">Intitulé du projet</label>
                    <input type="text" 
                    name="Intituleduprojet" 
                    value={inputs.Intituleduprojet|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Intitulé du projet" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">N du Lot</label>
                    <input type="text" 
                    name="NduLot" 
                    value={inputs.NduLot|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N du Lot" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname4" className="form-label">Intitulé du Lot</label>
                    <input type="text" name="IntituleduLot" 
                    value={inputs.IntituleduLot|| "null"} 
                    onChange={handleChange} className="form-control" placeholder="Intitulé du Lot" />
                  </div>
                  {/* Date View */}
                  <div className="mb-3">
                    <label className="form-label">Date de Notification</label>
                    <input type="date" 
                    name="DatedeNotification" 
                    value={inputs.DatedeNotification|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  {/* Date View */}
                  <div className="mb-3">
                    <label className="form-label">Date d'inscription</label>
                    <input type="date" 
                    name="Datedinscription" 
                    value={inputs.Datedinscription|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">AP Initiale</label>
                    <input type="text" 
                    name="APInitiale" 
                    value={inputs.APInitiale|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="AP Initiale" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">AP actuelle</label>
                    <input type="text" 
                    name="APActuelle" 
                    value={inputs.APActuelle|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="AP actuelle" />
                  </div>
                  <div>
                    <label htmlFor="project-overview" className="form-label">Entreprises</label><input value={inputs.entreprise.Entreprise }  className="form-label" disabled />
                    <select name="EntrepriseM" 
                    value={inputs.EntrepriseM|| "null"} 
                    onChange={handleChange}
                     className="form-control" data-toggle="select2" data-width="100%">
                    <Fragment>
{entreprises.map((post) => (
 <option key={post.id} value={post.id}>{post.Entreprise}</option>
 
))}
</Fragment>
                      
                    </select>
                    <div className="mb-3">
                      <label className="form-label">Montant en TTC</label>
                      <input type="text"
                      name="MontantenTTC" 
                      value={inputs.MontantenTTC|| "null"} 
                      onChange={handleChange}
                      className="form-control" placeholder="Montant en TTC" />
                    </div>  
                    <div className="mb-3">
                      <label className="form-label">Délai</label>
                      <input type="text" 
                      name="Delai" 
                      value={inputs.Delai|| "null"} 
                      onChange={handleChange}
                      className="form-control" placeholder="Délai" />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Cahier des charges</label><input value={inputs.Cahierdescharges }  className="form-label" disabled /> <br />
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Cahierdescharges"
                      value="Etude" 
                      onChange={handleChange}
                       className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio1">Etude</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Cahierdescharges"
                      value="Fourniture" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio2">Fourniture</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Cahierdescharges"
                      value="Réalisation" 
                      onChange={handleChange}
                      className="form-check-input"  defaultChecked />
                      <label className="form-check-label" htmlFor="customRadio3">Réalisation</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">N et Visa du Cahier des Charges</label>
                    <input type="text" 
                    name="NetVisaduCahierdesCharges" 
                    value={inputs.NetVisaduCahierdesCharges|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N et Visa du Cahier des Charges" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Choix de l offre</label><input value={inputs.Choixdeloffre }  className="form-label" disabled /> <br />
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Choixdeloffre"
                      value="Moins disante" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio1">Moins disante</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Choixdeloffre"
                      value="Mieux disante" 
                      onChange={handleChange}
                      className="form-check-input"  defaultChecked />
                      <label className="form-check-label" htmlFor="customRadio2">Mieux disante</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">N de procédure</label>
                    <input type="text" 
                    name="Ndeprocedure" 
                    value={inputs.Ndeprocedure|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de procédure" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Type de procédure</label><input value={inputs.Typedeprocedure }  className="form-label" disabled /> <br />
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Typedeprocedure"
                      value="Appel d offre" 
                      onChange={handleChange}
                      className="form-check-input"  defaultChecked />
                      <label className="form-check-label" htmlFor="customRadio1">Appel d offre</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Typedeprocedure"
                      value="Concours" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio2">Concours</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Typedeprocedure"
                      value="Consultations" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio2">Consultations</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">Date de Publication</label>
                    <input type="date" 
                    name="DatedePublication" 
                    value={inputs.DatedePublication || "2022-01-17"} 
                    onChange={handleChange} 
                    className="form-control" placeholder="Date de Publication" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">Journal de  Publication</label>
                    <input type="text" 
                    name="JournaldePublication" 
                    value={inputs.JournaldePublication || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Journal de  Publication" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Durée de Préparation des Offres</label>
                    <input type="text" 
                    name="DureedePreparationdesOffres" 
                    value={inputs.DureedePreparationdesOffres || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Durée de Préparation des Offres" />
                  </div>
                </div> {/* end col*/}
                <div className="col-xl-6">
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N de PV d'Ouverture</label>
                    <input type="text" 
                     name="NdePVdOuverture" 
                     value={inputs.NdePVdOuverture || "null"} 
                     onChange={handleChange}
                    className="form-control" placeholder="N de PV d'Ouverture" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de PV d'Ouverture</label>
                    <input type="date" 
                    name="DatedePVdOuverture" 
                    value={inputs.DatedePVdOuverture || "2022-01-17"} 
                    onChange={handleChange} 
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N de PV d'évaluation</label>
                    <input type="text" 
                    name="NdePVdevaluation" 
                    value={inputs.NdePVdevaluation || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de PV d'évaluation" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de PV d'évaluation</label>
                    <input type="date" 
                    name="DatedePVdevaluation" 
                    value={inputs.DatedePVdevaluation || "2022-01-17"} 
                    onChange={handleChange} 
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date d'Attribution</label>
                    <input type="date" 
                    name="DatedAttribution" 
                    value={inputs.DatedAttribution || "2022-01-17"} 
                    onChange={handleChange} 
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">journal d'Attribution</label>
                    <input type="text" 
                    name="journaldAttribution" 
                    value={inputs.journaldAttribution || "null"} 
                    onChange={handleChange} 
                    className="form-control" placeholder="journal d'Attribution" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date dépôt CMW</label>
                    <input type="date" 
                    name="DatedepotCMW" 
                    value={inputs.DatedepotCMW || "2022-01-17"} 
                    onChange={handleChange} 
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">Rapporteur</label>
                    <input type="text" 
                    name="Rapporteur" 
                    value={inputs.Rapporteur || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Rapporteur" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de programmation</label>
                    <input type="date" 
                     name="Datedeprogrammation" 
                     value={inputs.Datedeprogrammation|| "2022-01-17"} 
                     onChange={handleChange} 
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N de Visa CMW</label>
                    <input type="text" 
                    name="NdeVisaCMW" 
                    value={inputs.NdeVisaCMW|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de Visa CMW" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de Visa CMW</label>
                    <input type="date" 
                    name="DatedeVisaCMW" 
                    value={inputs.DatedeVisaCMW|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">N d'engagement</label>
                    <input type="text" 
                    name="Ndengagemen" 
                    value={inputs.Ndengagemen|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N d'engagement" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date d'engagement</label>
                    <input type="date" 
                    name="Datedengagemen" 
                    value={inputs.Datedengagemen|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date Signature de Marche</label>
                    <input type="date" 
                    name="DateSignaturedeMarche" 
                    value={inputs.DateSignaturedeMarche|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">N de l'ODS</label>
                    <input type="text" 
                    name="NdelODS" 
                    value={inputs.NdelODS|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de l'ODS" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de l'ODS</label>
                    <input type="date" 
                    name="DatedelODS" 
                    value={inputs.DatedelODS|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="project-overview" className="form-label">Observation</label>
                    <textarea className="form-control" 
                    name="Observation" 
                    value={inputs.Observation|| "null"} 
                    onChange={handleChange}
                    rows={5} placeholder="entrez votre observation"  />
                  </div>												
                </div> {/* end col*/}
              </div>
              {/* end row */}
              
            </div> {/* end card-body */}
            </form>
            </fieldset>
          </div> {/* end card*/}
        </div> {/* end col*/}
      </div>}
      {loading && <Loading />}
      {/* end row*/}
     
      <div className="col-xl-12" id="upload-ccharge">
        
      <div className="col-xl-12" id="upload-ccharge">
       
        <div className="col-12 text-center">
  <div className="mt-3">
    <h5 className="mb-2">Fichiers</h5>
    <div className="row mx-n1 g-0">
    <FilesList 
      files={showfiles}
      />
    </div> 
  </div>
</div>

        
      </div>

        
      </div>
      
    </div> {/* container */}
  </div> {/* content */}
</div>

    )
}



export default MDetail
