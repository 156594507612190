import React,{useState,useEffect} from 'react'
import { updateCcharge} from '../../libs/ccharge'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import { filesSearch } from  "../../libs/files";
import FilesList from '../../components/FilesList';

const UsDetail = (data) => {
    const [inputs, setInputs] = useState(data.post);
    const [files, setFiles] = useState([]);
    const [postid, setPostid] = useState("");
    const [showup, setShowup] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showfiles, setShowFiles] = useState([]);
  
  
    const notify = (msg,type)=>store.addNotification({
      title: "succès",
      message: msg,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
        showIcon: true
      }
    }); 
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
      console.log(inputs)
    }
    const handleSubmit = (event) => {
      event.preventDefault();
    
      create(inputs)
  
    }
    const create = async(post)=>{
      try {
  
        const author_id = localStorage.getItem("id")
        const graphqlQuery = {
          query: updateCcharge,
          variables: {
          id: post.id,
          NetIntituledelOperation: post.NetIntituledelOperation,
          Intituleduprojet: post.Intituleduprojet,
          NduLot: post.NduLot,
          IntituleduLot: post.IntituleduLot,
          DatedeNotification: post.DatedeNotification,
          Datedinscription: post.Datedinscription,
          APInitiale: post.APInitiale,
          APactuelle: post.APactuelle,
          Cahierdescharges: post.Cahierdescharges,
          DatedepotCMW: post.DatedepotCMW,
          DatedeprogrammationCMW: post.DatedeprogrammationCMW,
          Rapporteur: post.Rapporteur,
          NdeVisaCMW: post.NdeVisaCMW,
          DatedeVisaCMW: post.DatedeVisaCMW,
          typedepublication: post.typedepublication,
          Ndepublication: post.Ndepublication,
          Datedepublication: post.Datedepublication,
          JournaldePublication: post.JournaldePublication,
          DatedePublicationsurJournal: post.DatedePublicationsurJournal,
          Dureedepreparationdesoffres: post.Dureedepreparationdesoffres,
          Ndouverture: post.Ndouverture,
          Datedouverture: post.Datedouverture,
          Ndevaluationdesoffres: post.Ndevaluationdesoffres,
          Datedevaluationdesoffres: post.Datedevaluationdesoffres,
          Ndelettredecommande: post.Ndelettredecommande,
          Datedelettredecommande: post.Datedelettredecommande,
          Datedattribution: post.Datedattribution,
          Observation: post.Observation,
          author_id: post.author.id,
          },
        };
        fetch(API, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
        "Content-Type": "application/json",
        Accept: "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then(async (result) => {
            const res = await result.json();
            return res;
          })
          .then((response) => {
            let data = response;
            console.log(data)
            notify("Creation Cahier de charge "+ data.data.updateCcharge.NetIntituledelOperation +" avec succés","success")
            setPostid(data.data.updateCcharge.id)
            setShowup(true)
          })
          .catch((error) => {
            notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
          })
  
          
      } catch {
           
      }
  
      
  
      
  
    }
    useEffect(() => {
      handleLoadF(data.post.id);
    }, []);
    const handleLoadF = (id) => {
      const graphqlQuery = {
        query: filesSearch,
        variables: {
          column_name: "ccharge_id",
          id: id,
        },
      };
      try {
    
        
        
        fetch(API, {
          method: "POST",
          headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then(async (result) => {
            const res = await result.json();
            return res;
          })
          .then((response) => {
            let data = response.data.FilesSearch;
            console.log("files:"+ data)
            setShowFiles(data);
           
          })
          .catch((error) => {
            
          })
    
          
      } catch {
           
      }
    };
   
    const handleFiles = (event) => {
      event.preventDefault();
     
      filesPost(files)
    }
    const filesPost= async (files) => {
      setUploading(true)
      var myHeaders = new Headers();
      const author_id = localStorage.getItem("id")
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem("ggmpToken"));
      
      var formdata = new FormData();
      formdata.append("operations", "{\n        \"query\": \"mutation Files($files: Upload!,$author_id:String!,$ccharge_id:String!) { Files(files: $files,author_id:$author_id,ccharge_id:$ccharge_id) }\",\n        \"variables\": {\n  \"files\": null,\n\"author_id\":\""+author_id+"\"\n ,\n\"ccharge_id\":\""+postid+"\"\n        }\n    }");
      formdata.append("map", "{\n        \"0\": [\"variables.files\"]}");
      formdata.append("0", files);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(API, requestOptions)
        .then(response => response.text())
        .then((result) => {
          notify("fichier envoyé avec succès","success")
          handleLoadF(postid)
          setUploading(false)
      })
        .catch((error) => { notify("Erreur: verifier votre connexion","danger")
        setUploading(false)
      });
    
  
    };
  
    return (
        <div>
            
        </div>
    )
}

export default UsDetail
