export const SearchEnt = `
query EntSearch($key: String){EntSearch(key:$key){
  id
  Entreprise
  tel
  NIS
  NIF
  author {
    id
    name
    status
    email
    created_at
    updated_at
   
  }
  marche {
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    NetVisaduCahierdesCharges
    Choixdeloffre
    Ndeprocedure
    Typedeprocedure
    DatedePublication
    JournaldePublication
    DureedePreparationdesOffres
    NdePVdOuverture
    DatedePVdOuverture
    NdePVdevaluation
    DatedePVdevaluation
    DatedAttribution
    journaldAttribution
    DatedepotCMW
    Rapporteur
    Datedeprogrammation
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagemen
    Datedengagemen
    DateSignaturedeMarche
    NdelODS
    DatedelODS
    Observation
    
    created_at
    updated_at
  }
convention {
    id
    NdelaConvention
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    Choixdeloffre
    Nprocedure
    typedeprocedure
    DatedePublication
    DureedePreparationdesOffres
    NPVdOuverture
    DatedePVdOuverture
    NPVdevaluation
    DatedePVdevaluation
    DatedAttribution
    JournaleAttribution
    DatedepotauCF
    Ndengagement
    Datedengagement
    DateSignaturedelaconvention
    NdelODS
    DatedelODS
    Observation
  
  }   
  avenant {
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    APInitiale
    APActuelle
    EntrepriseM
    MontantduMarcheenTTC
    DelaiduMarche
    typedeMarche
    typedAvenant
    MontantdelavenantenTTC
    NouveauMontantduMarcheenTTC
    NouveauDelai
    NetVisadeMarche
    NdODSdemarrage
    DatedODSdemarrage
    NdODSdarret
    DatedODSdarret
    NdODSdereprise
    DatedODSdereprise
    PVdereceptionprovisoire
    DatedepotCMW
    DatedeprogrammationCMW
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagement
    Datedengagement
    DateSignaturedelavenant
    NdelODS
    DatedelODS
    Observation
   
  }
  created_at
  updated_at
}
}`;

export const Tentreprises = `
{
  entreprises(first: 10, page: 1) {
    paginatorInfo {
     
      total
    }
    
  }
}
`;

export const createEtreprise = `
mutation 
createEntreprise(
    $Entreprise: String,
    $tel: String,
    $NIS: String,
    $NIF: String,
    $author_id: String,) {
    createEntreprise(
      Entreprise: $Entreprise
      tel: $tel
      NIS: $NIS
      NIF: $NIF
      author_id: $author_id
      marche_id: 0
      convention_id: 0
      avenant_id: 0
  ){
    id
    Entreprise
    tel
    NIS
    NIF
  
    created_at
    updated_at
  }
}
`;
export const updateEntreprise = `
mutation 
updateEntreprise(
    $id: String,
    $Entreprise: String,
    $tel: String,
    $NIS: String,
    $NIF: String,
    $author_id: String,) {
      updateEntreprise(
      id: $id
      Entreprise: $Entreprise
      tel: $tel
      NIS: $NIS
      NIF: $NIF
      author_id: $author_id
      marche_id: 0
      convention_id: 0
      avenant_id: 0
  ){
    id
    Entreprise
    tel
    NIS
    NIF
  
    created_at
    updated_at
  }
}
`;
export const deletEntreprise = `
mutation 
deleteEntreprise(
  $id: String!,) {
    deleteEntreprise(id: $id){
    id
    Entreprise
    }
}
`;
