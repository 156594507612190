import React,{useState,useEffect, Fragment} from 'react'
import { updateConvention} from '../../libs/convention'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import { filesSearch } from  "../../libs/files";
import FilesList from '../../components/FilesList';
import {SearchCC} from '../../libs/ccharge'
import {SearchEnt} from '../../libs/entreprise'
import Loading from '../../components/Loading';


const CoModif= (data) => {
  const [entreprises, setEntreprises] = useState([]);
  const [ccharge, setCcharge] = useState([]);
  const [inputs, setInputs] = useState(data.post);
  const [file, setFiles] = useState([]);
  const [postid, setPostid] = useState(data.post.id);
  const [showup, setShowup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showfiles, setShowFiles] = useState([]);
  const [loading, setloading] = useState(false);


  const notify = (msg,type)=>store.addNotification({
    title: "succès",
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  }); 
  const handleChange = (event) => {
    
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs)
    create(inputs)

  }
  const create = async(post)=>{
    try {

      const author_id = localStorage.getItem("id")
      const graphqlQuery = {
        query: updateConvention,
        variables: {
        id: post.id,
NdelaConvention: post.NdelaConvention,
NetIntituledelOperation: post.NetIntituledelOperation,
Intituleduprojet: post.Intituleduprojet,
NduLot: post.NduLot,
IntituleduLot: post.IntituleduLot,
DatedeNotification: post.DatedeNotification,
Datedinscription: post.Datedinscription,
APInitiale: post.APInitiale,
APActuelle: post.APActuelle,
EntrepriseM: post.EntrepriseM,
MontantenTTC: post.MontantenTTC,
Delai: post.Delai,
Cahierdescharges: post.Cahierdescharges,
Choixdeloffre: post.Choixdeloffre,
Nprocedure: post.Nprocedure,
typedeprocedure: post.typedeprocedure,
DatedePublication: post.DatedePublication,
DureedePreparationdesOffres: post.DureedePreparationdesOffres,
NPVdOuverture: post.NPVdOuverture,
DatedePVdOuverture: post.DatedePVdOuverture,
NPVdevaluation: post.NPVdevaluation,
DatedePVdevaluation: post.DatedePVdevaluation,
DatedAttribution: post.DatedAttribution,
JournaleAttribution: post.JournaleAttribution,
DatedepotauCF: post.DatedepotauCF,
Ndengagement: post.Ndengagement,
Datedengagement: post.Datedengagement,
DateSignaturedelaconvention: post.DateSignaturedelaconvention,
NdelODS: post.NdelODS,
DatedelODS: post.DatedelODS,
Observation: post.Observation,
author_id: post.author.id,
ccharge_id: post.ccharge.id,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          console.log(data)
          notify("Creation Cahier de charge "+ data.data.updateConvention.NdelaConvention +" avec succés","success")
          setPostid(data.data.updateConvention.id)
          setShowup(true)
        })
        .catch((error) => {
          notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
        })

        
    } catch {
         
    }

    

    

  }
  useEffect(() => {
    handleLoadF(data.post.id);
    try {
      setloading(true)
      const key = " "
      const graphqlQuery = {
        query: SearchCC,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.CcSearch;
          
          setCcharge(data);
          setloading(false)
        });
    } catch {}
    try {
      setloading(true)
      const key = ""
      const graphqlQuery = {
        query: SearchEnt,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.EntSearch;
          setEntreprises(data);
          setloading(false)
        });
    } catch {}
  }, []);
  const handleLoadF = (id) => {
    const graphqlQuery = {
      query: filesSearch,
      variables: {
        column_name: "convention_id",
        id: id,
      },
    };
    try {
  
      
      
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.FilesSearch;
          console.log("files:"+ data)
          setShowFiles(data);
         
        })
        .catch((error) => {
          
        })
  
        
    } catch {
         
    }
  };
 
  const handleFiles = (event) => {
    event.preventDefault();
   
    filesPost(file)
  }
  const filesPost= async (files) => {
    setUploading(true)
    var myHeaders = new Headers();
    const author_id = localStorage.getItem("id")
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("ggmpToken"));
    
    var formdata = new FormData();
    const operations = { query: `
    mutation files($file:Upload!) {
      Files(
        files: $file author_id: ${author_id} ccharge_id: 0 marche_id: 0 convention_id: ${postid} avenant_id: 0
      )
    }
  `
  } ;
    const map = { 
      "0": ["variables.file"],
    };
    formdata.append("operations", JSON.stringify(operations))
    console.log(JSON.stringify(operations))
    formdata.append("map", JSON.stringify(map));
    console.log(JSON.stringify(map))
    formdata.append("0", file);
    
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    

    
    fetch(API, requestOptions)
      .then(response => response.text())
      .then((result) => {
        notify("fichier envoyé avec succès","success")
        handleLoadF(postid)
        setUploading(false)
    })
      .catch((error) => { notify("Erreur: verifier votre connexion","danger")
      setUploading(false)
    });
  

  };
    return (
      
      <div className="content-page">
  <div className="content">
    {/* Start Content*/}
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                <li className="breadcrumb-item"><a href="javascript: void(0);">Convention</a></li>
                <li className="breadcrumb-item active">Modification</li>
              </ol>
            </div>
            <h4 className="page-title">Modification de Convention</h4>
          </div>
        </div>
      </div>     
      {/* end page title */} 
      {!loading &&
      <div className="row">
        <div className="col-12">
          <div className="card">
          <form onSubmit={handleSubmit} >
            <div className="card-body">
              <div className="row">
                <div className="col-xl-6">
                <div className="mb-3">
                  <label htmlFor="project-overview" className="form-label">Cahier des Charge</label>
                    <select 
                    name="ccharge_id" 
                    value={inputs.ccharge_id || "1"} 
                    onChange={handleChange}
                     className="form-control" data-toggle="select2" data-width="100%">
                       <option  value={"1" } 
                    onChange={handleChange}>Selectionner</option>
                    <Fragment>
{ccharge.map((post) => (
 <option key={post.id} value={post.id}>{post.NetIntituledelOperation}</option>
 
))}
</Fragment>
                      
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">N de la Convention</label>
                    <input type="text" 
                    name="NdelaConvention" 
                    value={inputs.NdelaConvention } 
                    onChange={handleChange}
                    className="form-control" placeholder="N de la Convention" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">N et Intitulé de l'Opération</label>
                    <input type="text" 
                    name="NetIntituledelOperation" 
                    value={inputs.NetIntituledelOperation } 
                    onChange={handleChange}
                    className="form-control" placeholder="N et Intitulé de l'Opération" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">Intitulé du projet</label>
                    <input type="text" 
                    name="Intituleduprojet" 
                    value={inputs.Intituleduprojet } 
                    onChange={handleChange}
                    className="form-control" placeholder="Intitulé du projet" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname4" className="form-label">N du Lot</label>
                    <input type="text" 
                    name="NduLot" 
                    value={inputs.NduLot } 
                    onChange={handleChange}
                    className="form-control" placeholder="N du Lot" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname4" className="form-label">Intitulé du Lot</label>
                    <input type="number" 
                    name="IntituleduLot" 
                    value={inputs.IntituleduLot} 
                    onChange={handleChange}
                    className="form-control" placeholder="Intitulé du Lot" />
                  </div>
                  {/* Date View */}
                  <div className="mb-3">
                    <label className="form-label">Date de Notification</label>
                    <input type="date" 
                    name="DatedeNotification" 
                    value={inputs.DatedeNotification|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  {/* Date View */}
                  <div className="mb-3">
                    <label className="form-label">Date d'inscription</label>
                    <input type="date" 
                    name="Datedinscription" 
                    value={inputs.Datedinscription|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">AP Initiale</label>
                    <input type="text" 
                    name="APInitiale" 
                    value={inputs.APInitiale} 
                    onChange={handleChange}
                    className="form-control" placeholder="AP Initiale" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">AP actuelle</label>
                    <input type="number" 
                    name="APActuelle" 
                    value={inputs.APActuelle} 
                    onChange={handleChange}
                    className="form-control" placeholder="AP actuelle" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="project-overview" className="form-label">Entreprises</label>
                    <select name="EntrepriseM" 
                    value={inputs.EntrepriseM} 
                    onChange={handleChange}
                     className="form-control" data-toggle="select2" data-width="100%">
                       <option  value={"1" } 
                    onChange={handleChange}>Selectionner</option>
                    <Fragment>
{entreprises.map((post) => (
 <option key={post.id} value={post.id}>{post.Entreprise}</option>
 
))}
</Fragment>
                      
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Montant en TTC</label>
                    <input type="text" 
                    name="MontantenTTC" 
                    value={inputs.MontantenTTC} 
                    onChange={handleChange}
                    className="form-control" placeholder="Montant en TTC" />
                  </div>  
                  <div className="mb-3">
                    <label className="form-label">Délai</label>
                    <input type="text" 
                    name="Delai" 
                    value={inputs.Delai} 
                    onChange={handleChange}
                    className="form-control" placeholder="Délai" />
                  </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Cahier des charges</label> <br />
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Cahierdescharges"
                      value="Etude" 
                      onChange={handleChange}
                       className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio1">Etude</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Cahierdescharges"
                      value="Fourniture" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio2">Fourniture</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Cahierdescharges"
                      value="Réalisation" 
                      onChange={handleChange}
                      className="form-check-input"  defaultChecked />
                      <label className="form-check-label" htmlFor="customRadio3">Réalisation</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Choix de l offre</label> <br />
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Choixdeloffre"
                      value="Moins disante" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio1">Moins disante</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Choixdeloffre"
                      value="Mieux disante" 
                      onChange={handleChange}
                      className="form-check-input"  defaultChecked />
                      <label className="form-check-label" htmlFor="customRadio2">Mieux disante</label>
                    </div>
                  </div>
                <div className="mb-3">
                  <label className="form-label">N procédure</label>
                  <input type="text" 
                  name="Nprocedure" 
                  value={inputs.Nprocedure} 
                  onChange={handleChange}
                  className="form-control" placeholder="N procédure" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Type de procédure</label> <br />
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Typedeprocedure"
                      value="Appel d offre" 
                      onChange={handleChange}
                      className="form-check-input"  defaultChecked />
                      <label className="form-check-label" htmlFor="customRadio1">Appel d offre</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Typedeprocedure"
                      value="Concours" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio2">Concours</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input type="radio" 
                      name="Typedeprocedure"
                      value="Consultations" 
                      onChange={handleChange}
                      className="form-check-input"  />
                      <label className="form-check-label" htmlFor="customRadio2">Consultations</label>
                    </div>
                  </div>
                <div className="mb-3">
                  <label htmlFor="projectname" className="form-label">Date de Publication</label>
                  <input type="date" 
                  name="DatedePublication" 
                  value={inputs.DatedePublication || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" placeholder="Date de Publication" />
                </div>
                <div className="mb-3">
                  <label htmlFor="projectname" className="form-label">Durée de Préparation des Offres</label>
                  <input type="text" 
                  name="DureedePreparationdesOffres" 
                  value={inputs.DureedePreparationdesOffres} 
                  onChange={handleChange}
                  className="form-control" placeholder="Durée de Préparation des Offres" />
                </div>
              </div> {/* end col*/}
              <div className="col-xl-6">
                <div className="mb-3">
                  <label htmlFor="projectname3" className="form-label">N de PV d'Ouverture</label>
                  <input type="text" 
                  name="NPVdOuverture" 
                  value={inputs.NPVdOuverture} 
                  onChange={handleChange}
                  className="form-control" placeholder="N de PV d'Ouverture" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date de PV d'Ouverture</label>
                  <input type="date" 
                   name="DatedePVdOuverture" 
                   value={inputs.DatedePVdOuverture || "2022-01-17"} 
                   onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label htmlFor="projectname3" className="form-label">N de PV d'évaluation</label>
                  <input type="text" 
                  name="NPVdevaluation" 
                  value={inputs.NPVdevaluation } 
                  onChange={handleChange}
                  className="form-control" placeholder="N de PV d'évaluation" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date de PV d'évaluation</label>
                  <input type="date" 
                  name="DatedePVdevaluation" 
                  value={inputs.DatedePVdevaluation || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date d'Attribution</label>
                  <input type="date" 
                  name="DatedAttribution" 
                  value={inputs.DatedAttribution || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label htmlFor="projectname3" className="form-label">journal d'Attribution</label>
                  <input type="text" 
                  name="JournaleAttribution" 
                  value={inputs.JournaleAttribution } 
                  onChange={handleChange}
                  className="form-control" placeholder="journal d'Attribution" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date dépôt au CF</label>
                  <input type="date" 
                  name="DatedepotauCF" 
                  value={inputs.DatedepotauCF || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label htmlFor="projectname3" className="form-label">N d engagement</label>
                  <input type="text" 
                  name="Ndengagement" 
                  value={inputs.Ndengagement } 
                  onChange={handleChange}
                  className="form-control" placeholder="N d engagement" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date d engagement</label>
                  <input type="date" 
                  name="Datedengagement" 
                  value={inputs.Datedengagement || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date Signature de la convention</label>
                  <input type="date" 
                  name="DateSignaturedelaconvention" 
                  value={inputs.DateSignaturedelaconvention || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label className="form-label">N de l ODS</label>
                  <input type="text" 
                  name="NdelODS" 
                  value={inputs.NdelODS } 
                  onChange={handleChange}
                  className="form-control" placeholder="N de l ODS" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date de l ODS</label>
                  <input type="date" 
                  name="DatedelODS" 
                  value={inputs.DatedelODS || "2022-01-17"} 
                  onChange={handleChange}
                  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                </div>
                <div className="mb-3">
                  <label htmlFor="project-overview" className="form-label">Observation</label>
                  <textarea className="form-control" 
                  name="Observation" 
                  value={inputs.Observation } 
                  onChange={handleChange}
                  rows={5} placeholder="entrez votre observation" defaultValue={""} />
                </div>												
              </div> {/* end col*/}
            </div>
            {/* end row */}
            <div className="row mt-3">
              <div className="col-12 text-center">
                  <button type="submit" className="btn btn-warning waves-effect waves-light m-1" ><i className="fe-check-circle me-1" /> Modifier</button>
               </div>
              </div>
            </form>
            
          </div> {/* end card-body */}
        </div> {/* end card*/}
      </div> }
      {loading && <Loading />}
      {/* end row*/}
      
      <div className="col-xl-12" id="upload-ccharge">
        <form onSubmit={handleFiles}>
        <div className="mb-3">
          
            <input type="hidden" id="tablename" name="tablename" defaultValue="ccharges" />
            <input type="hidden" id="tablecontid" name="tablecontid" />
            <input type="file"  className="form-control" onChange={(e)=>setFiles( e.target.files[0])} />
          
          </div>
        <div className="col-12 text-center">
      {!uploading ?  <button type="submit" className="btn btn-success waves-effect waves-light m-1"  ><i className="fe-check-circle me-1" /> UPLOAD</button>
       :
       <button type="submit" className="btn btn-warning waves-effect waves-light m-1" disabled ><i className="fe-check-circle me-1" /> UPLOADING</button>
       } </div>
        </form>
        <div className="col-12 text-center">
  <div className="mt-3">
    <h5 className="mb-2">Fichiers</h5>
    <div className="row mx-n1 g-0">
    <FilesList 
      files={showfiles}
      />
    </div> 
  </div>
</div>

        
      </div>
      
       
    </div>
    
  </div> {/* container */}
</div> 

    ) 
}



export default CoModif
