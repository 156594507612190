import React, { useState, useEffect, Fragment } from "react";
import {Link} from 'react-router-dom'
import {Storage} from '../libs/costants'


const FilesList = (data) => {
  const [path, setpath] = useState("");
  const downloadFile = (e) => {
    e.preventDefault()
    
    window.open(path);
  
  }
  
  return (
    <Fragment>
{data.files.map((file) => (
  
  <div className="col-xl-3 col-lg-6">
  <div className="card m-1 shadow-none border">
    <div className="p-2">
      <div className="row align-items-center">
        <div className="col-auto pe-0">
          <div className="avatar-sm">
            <span className="avatar-title bg-light text-secondary rounded">
              <i className={"mdi mdi-file-"+ file.filepath.split('.').pop()+" font-18"} />
              
            </span>
          </div>
        </div>
        <div className="col">
          <Link  className="text-muted fw-medium" onClick={(e)=>window.open(Storage+ file.filepath.replace(/^.*[\\\/]/, ''))} >{file.filepath.replace(/^.*[\\\/]/, '')}</Link>
          
          <p className="text-muted text-uppercase mb-0 font-13"><i className="mdi mdi-account-circle" /> <small>{file.author.name}</small></p>
        </div>
      </div> 
    </div> 
  </div> 
</div>
))}
</Fragment>
  );
};

export default FilesList;