export const API = "https://gmpapi.duckdns.org/graphql";
export const Storage = "https://gmpapi.duckdns.org/storage/uploads/files/";
export const Categories = `
query Categories {
    categories{
      paginatorInfo{
        total
      }
      data{
        id
        name
        posts{id
        title}
      }
    }
    
  }
`;
export const Category = `
query category($id: Int!){
  category(id:$id){
    name
    posts{
      id
      title
      content
      img
    }
  }
}
`;
export const Posts = (page) => {
  const graphqlQuery = {
    query: `
query posts($page: Int){
  posts(page:$page){
    paginatorInfo{
      currentPage
      hasMorePages
      perPage
      total
    }
    data{
      id
      title
      content
      img
      category{
        name
      }
    }
  }
}
`,
    variables: {
      page: page,
    },
  };
  fetch(API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(graphqlQuery),
  })
    .then((response) => {
      return response.json();
    })
    .then((resData) => {
      const posts = [];
      const data = resData.data.posts.data;
      for (const key in data) {
        const post = {
          id: key,
          ...data[key],
        };
        posts.push(post);
      }
      console.log(posts);
      return posts;
    });
};
export const Post = `
query post($id: Int!){
  post(id:$id){
    title
    content
    img
    file
    category{
      name
    }
  }
}
`;
export const Search = {
  query: `
query FetchPosts($key: String!, $page: Int) {
  search(key:$key page:$page){
    paginatorInfo{
      total
      currentPage
      hasMorePages
    }
    data{
      id
      title
      content
      img
      category{
        name
      }
    }
  
  }
}
`,
};
export const me = `
query me {
  me{
    id
    name
    email
    api_token
  }
}
`;

export const login = `
mutation login($email: String!, $password: String!){
  login(email:$email password:$password){
    id
    name
    status
    api_token
  }
}
`;
export const createCategory = `
mutation ($name: String!){
  createCategory(name:$name){
    id
    name
  }
}
`;
export const removePost = `
mutation ($id: ID!){
  deletePost(id: $id){
    id
  }
}
`;
export const createPost = `
mutation ($title: String!,$content: String!,$author_id: Int, $category_id: Int ){
  createPost(title:$title content:$content author_id:$author_id category_id:$category_id){
    id
    title
    content
    author{
      name
    }
    category{name}
  }
}
`;
