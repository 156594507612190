import React from "react";

const Header = (data) => {
  return (
    <div className="navbar-custom">
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          <li className="dropdown notification-list topbar-dropdown">
            {" "}
            <a
              className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              {" "}
              <span className="pro-user-name ms-1">
                {" "}
                {localStorage.getItem("username")}{" "}
                <i className="mdi mdi-chevron-down" />{" "}
              </span>{" "}
            </a>
            <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
              {/* item*/}
              <div className="dropdown-header noti-title">
                <h6 className="text-overflow m-0">Bienvenue !</h6>
              </div>
              {/* item*/}
              <a href="#users-password" className="dropdown-item notify-item">
                {" "}
                <i className="fe-user" /> <span>Mon compte</span>{" "}
              </a>
              <div className="dropdown-divider" />
              {/* item*/}
              <a onClick={data.onLogout} className="dropdown-item notify-item">
                {" "}
                <i className="fe-log-out" /> <span>Logout</span>{" "}
              </a>{" "}
            </div>
          </li>
        </ul>
        {/* LOGO */}
        <div className="logo-box">
          {" "}
          <a href="index" className="logo logo-dark text-center">
            {" "}
            <span className="logo-sm">
              {" "}
              <img src="../assets/images/logo-sm.png" alt height={22} />
              {/* <span class="logo-lg-text-light">UBold</span> */}
            </span>{" "}
            <span className="logo-lg">
              {" "}
              <img src="../assets/images/logo-dark.png" alt height={20} />
              {/* <span class="logo-lg-text-light">U</span> */}
            </span>{" "}
          </a>{" "}
          <a href="/" className="logo logo-light text-center">
            {" "}
            <span className="logo-sm">
              {" "}
              <img src="../assets/images/logo-sm.png" alt height={22} />{" "}
            </span>{" "}
            <span className="logo-lg">
              {" "}
              <img src="../assets/images/logo-light.png" alt height={20} />
            </span>{" "}
          </a>{" "}
        </div>
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button className="button-menu-mobile waves-effect waves-light">
              {" "}
              <i className="fe-menu" />
            </button>
          </li>
          <li>
            {/* Mobile menu toggle (Horizontal Layout)*/}
            <a
              className="navbar-toggle nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
            >
              <div className="lines">
                {" "}
                <span /> <span /> <span />
              </div>
            </a>
            {/* End mobile menu toggle*/}
          </li>
        </ul>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default Header;
