import React from 'react'

const Nodata = () => {
    return (
        <div className="col-lg-12">
  <div className="card text-white bg-danger mb-3">
    <div className="card-header bg-danger"></div>
    <div className="card-body">
      <h4 className="card-title text-white">pas de data fait un autre recherche </h4>
      
    </div>
  </div>
</div>

    )
}

export default Nodata
