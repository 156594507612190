import React, {useState, useEffect,Fragment} from 'react'
import { Link } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import {API} from '../../libs/costants'
import {SearchAv, TAvenant, deletAvenant} from '../../libs/avenant'
import AvListitem from '../../components/avenant/AvListitem'
import Loading from '../../components/Loading'
import Nodata from '../../components/Nodata'
import AvDetail from './AvDetail'
import  AvModif from './AvModif'

const AvSearch = (data) => {
  const [totale, setTotale] = useState("");
  const [ccharge, setCcharge] = useState([]);
  const [cloading, setCloading] = useState(false);
  const [key, setKey] = useState("");
  const [showdetail, setshowdetail] = useState(false);
  const [modif, setmodif] = useState(false);
  const [p, setp] = useState({});
  const notify = (msg,type)=>store.addNotification({
    title: "succès",
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  }); 
  const show = async (p) =>{
    setshowdetail(true)
    setp(p)
  }
  const modify = async (p) =>{
    setmodif(true)
    setp(p)
  }
  const delet = async (id) =>{
    try {
      const graphqlQuery = {
        query: deletAvenant,
        variables: {
          id: id,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.deleteAvenant;
          notify("Cahier de charge "+ data.NetIntituledelOperation +"suprimé avec succés","success")
          setCcharge(prevccharges =>{
            return prevccharges.filter(post => post.id !== data.id)
          })
          setTotale( totale - 1)
        }).catch((error) => {
          notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
        })
    } catch {}
   
    
    
  }
  const reset= ()=>{
    setmodif(false) 
    setshowdetail(false)
  } 

  useEffect(()=>{
    loadSearch("")
    try {
      const graphqlQuery = {
        query: TAvenant,
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.avenants.paginatorInfo.total;
          setTotale(data);
        });
    } catch {}
  },[])
  const handleSearch = (event) => {

    setKey(event.target.value)
    loadSearch(key)
  }
 /* function removeNull(obj) {
    console.log(obj)
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    
    console.log(obj)
     setCcharge(obj);
     
    };*/
  const loadSearch = async (key) => {
 
    setCloading(true);
    try {
      const graphqlQuery = {
        query: SearchAv,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.ASearch;
          setCcharge(data);
          setCloading(false);
        });
    } catch {}
 
  }
  if(showdetail){
    return <AvDetail post={p} onReset={reset}  />
   }
   if(modif){
    return < AvModif post={p} onReset={reset} />
   }else{
    return (
      <div className="content-page">
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
        <div>
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                <li className="breadcrumb-item active">Avenants</li>
              </ol>
            </div>
            <h4 className="page-title">List des Avenants</h4>
          </div>
        </div>
      </div>     
      {/* end page title */} 
      <div className="row">
        <div className="col-md-6 col-xl-6">
          <div className="widget-rounded-circle card">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="avatar-lg rounded-circle bg-success">
                    <i className="fe-check-circle font-22 avatar-title text-white" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-end">
                    <h3 className="text-dark mt-1"><span data-plugin="counterup" id="totale" />{totale}</h3>
                    <p className="text-muted mb-1 text-truncate">Total Avenants</p>
                  </div>
                </div>
              </div> {/* end row*/}
            </div>
          </div> {/* end widget-rounded-circle*/}
        </div> {/* end col*/}
        {data.isAdmin &&
        <div className="col-md-6 col-xl-6">
          <div className="widget-rounded-circle card">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="avatar-lg rounded-circle bg-transparent">
                  
                    <i className="fe-plus-circle font-22 avatar-title text-white" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-end">
                  <Link to="/avenants-create" className="btn btn-danger waves-effect waves-light">
                   <h3 className="fe-plus-circle font-22 avatar-title text-white"> Ajouter Nouveau</h3></Link>
                    
                  </div>
                </div>
              </div> {/* end row*/}
            </div>
          </div> {/* end widget-rounded-circle*/}
        </div> }
      </div>
    </div>
    
    
    <div className="row">
            <div className="col-lg-12">
              <div className="search-result-box card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8 offset-md-2">
                      <div className="pt-3 pb-4">
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Recherche" onChange={(e)=>handleSearch(e)}/>
                          <button type="button" className="input-group-text btn waves-effect waves-light btn-blue"><i className="fa fa-search me-1" /> Recherche</button>
                        </div>
                       
                      </div>
                     
    
                    </div>
                  </div>
                  {/* end row */}

                  <div className="row">
                    {Object.keys(ccharge).length === 0 && <Nodata />}
                  { cloading && <Loading />}
            {!cloading &&
  
  <Fragment>
    
{ccharge.map((post) =>  {
  return <AvListitem
  key={post.id}
  post={post}
  onModif={modify}
  onView={show}
  onDelet={delet}
  isAdmin={data.isAdmin}
/>
}

)}
</Fragment>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> {/* container */}
      </div> {/* content */}
      {/* Footer Start */}
     
    </div>
    )
        }
}

export default AvSearch
