export const SearchCC = `
query CcSearch($key: String){CcSearch(key:$key){
  id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APactuelle
    Cahierdescharges
    DatedepotCMW
    DatedeprogrammationCMW
    Rapporteur
    NdeVisaCMW
    DatedeVisaCMW
    typedepublication
    Ndepublication
    Datedepublication
    JournaldePublication
    DatedePublicationsurJournal
    Dureedepreparationdesoffres
    Ndouverture
    Datedouverture
    Ndevaluationdesoffres
    Datedevaluationdesoffres
    Ndelettredecommande
    Datedelettredecommande
    Datedattribution
    Observation
    author{
      name
      email
    }
    created_at
    updated_at
}
}`;

export const Tccharges = `
{
  ccharges(first: 10, page: 1) {
    paginatorInfo {
     
      total
    }
    
  }
}
`;
export const cinit = {
  id: "",
  NetIntituledelOperation: "",
  Intituleduprojet: "",
  NduLot: "",
  IntituleduLot: "",
  DatedeNotification: "",
  Datedinscription: "",
  APInitiale: "",
  APactuelle: "",
  Cahierdescharges: "",
  DatedepotCMW: "",
  DatedeprogrammationCMW: "",
  Rapporteur: "",
  NdeVisaCMW: "",
  DatedeVisaCMW: "",
  typedepublication: "",
  Ndepublication: "",
  Datedepublication: "",
  JournaldePublication: "",
  DatedePublicationsurJournal: "",
  Dureedepreparationdesoffres: "",
  Ndouverture: "",
  Datedouverture: "",
  Ndevaluationdesoffres: "",
  Datedevaluationdesoffres: "",
  Ndelettredecommande: "",
  Datedelettredecommande: "",
  Datedattribution: "",
  Observation: "",
  author: {
    name: "",
    email: "",
  },
  created_at: "",
  updated_at: "",
};
export const createCcharge = `
mutation 
createCcharge(
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $DatedeNotification: String,
  $Datedinscription: String,
  $APInitiale: String,
  $APactuelle: String,
  $Cahierdescharges: String,
  $DatedepotCMW: String,
  $DatedeprogrammationCMW: String,
  $Rapporteur: String,
  $NdeVisaCMW: String,
  $DatedeVisaCMW: String,
  $typedepublication: String,
  $Ndepublication: String,
  $Datedepublication: String,
  $JournaldePublication: String,
  $DatedePublicationsurJournal: String,
  $Dureedepreparationdesoffres: String,
  $Ndouverture: String,
  $Datedouverture: String,
  $Ndevaluationdesoffres: String,
  $Datedevaluationdesoffres: String,
  $Ndelettredecommande: String,
  $Datedelettredecommande: String,
  $Datedattribution: String,
  $Observation: String,
  $author_id: String,) {
    createCcharge(
        NetIntituledelOperation: $NetIntituledelOperation
        Intituleduprojet: $Intituleduprojet
        NduLot: $NduLot
        IntituleduLot: $IntituleduLot
        DatedeNotification: $DatedeNotification
        Datedinscription: $Datedinscription
        APInitiale: $APInitiale
        APactuelle: $APactuelle
        Cahierdescharges: $Cahierdescharges
        DatedepotCMW: $DatedepotCMW
        DatedeprogrammationCMW: $DatedeprogrammationCMW
        Rapporteur: $Rapporteur
        NdeVisaCMW: $NdeVisaCMW
        DatedeVisaCMW: $DatedeVisaCMW
        typedepublication: $typedepublication
        Ndepublication: $Ndepublication
        Datedepublication: $Datedepublication
        JournaldePublication: $JournaldePublication
        DatedePublicationsurJournal: $DatedePublicationsurJournal
        Dureedepreparationdesoffres: $Dureedepreparationdesoffres
        Ndouverture: $Ndouverture
        Datedouverture: $Datedouverture
        Ndevaluationdesoffres: $Ndevaluationdesoffres
        Datedevaluationdesoffres: $Datedevaluationdesoffres
        Ndelettredecommande: $Ndelettredecommande
        Datedelettredecommande: $Datedelettredecommande
        Datedattribution: $Datedattribution
        Observation: $Observation
        author_id:$author_id
  ){
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APactuelle
    Cahierdescharges
    DatedepotCMW
    DatedeprogrammationCMW
    Rapporteur
    NdeVisaCMW
    DatedeVisaCMW
    typedepublication
    Ndepublication
    Datedepublication
    JournaldePublication
    DatedePublicationsurJournal
    Dureedepreparationdesoffres
    Ndouverture
    Datedouverture
    Ndevaluationdesoffres
    Datedevaluationdesoffres
    Ndelettredecommande
    Datedelettredecommande
    Datedattribution
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
    }
    
    created_at
    updated_at
  }
}
`;
export const updateCcharge = `
mutation 
updateCcharge(
  $id: String,
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $DatedeNotification: String,
  $Datedinscription: String,
  $APInitiale: String,
  $APactuelle: String,
  $Cahierdescharges: String,
  $DatedepotCMW: String,
  $DatedeprogrammationCMW: String,
  $Rapporteur: String,
  $NdeVisaCMW: String,
  $DatedeVisaCMW: String,
  $typedepublication: String,
  $Ndepublication: String,
  $Datedepublication: String,
  $JournaldePublication: String,
  $DatedePublicationsurJournal: String,
  $Dureedepreparationdesoffres: String,
  $Ndouverture: String,
  $Datedouverture: String,
  $Ndevaluationdesoffres: String,
  $Datedevaluationdesoffres: String,
  $Ndelettredecommande: String,
  $Datedelettredecommande: String,
  $Datedattribution: String,
  $Observation: String,
  $author_id: String,) {
    updateCcharge(
        id: $id
        NetIntituledelOperation: $NetIntituledelOperation
        Intituleduprojet: $Intituleduprojet
        NduLot: $NduLot
        IntituleduLot: $IntituleduLot
        DatedeNotification: $DatedeNotification
        Datedinscription: $Datedinscription
        APInitiale: $APInitiale
        APactuelle: $APactuelle
        Cahierdescharges: $Cahierdescharges
        DatedepotCMW: $DatedepotCMW
        DatedeprogrammationCMW: $DatedeprogrammationCMW
        Rapporteur: $Rapporteur
        NdeVisaCMW: $NdeVisaCMW
        DatedeVisaCMW: $DatedeVisaCMW
        typedepublication: $typedepublication
        Ndepublication: $Ndepublication
        Datedepublication: $Datedepublication
        JournaldePublication: $JournaldePublication
        DatedePublicationsurJournal: $DatedePublicationsurJournal
        Dureedepreparationdesoffres: $Dureedepreparationdesoffres
        Ndouverture: $Ndouverture
        Datedouverture: $Datedouverture
        Ndevaluationdesoffres: $Ndevaluationdesoffres
        Datedevaluationdesoffres: $Datedevaluationdesoffres
        Ndelettredecommande: $Ndelettredecommande
        Datedelettredecommande: $Datedelettredecommande
        Datedattribution: $Datedattribution
        Observation: $Observation
        author_id:$author_id
  ){
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APactuelle
    Cahierdescharges
    DatedepotCMW
    DatedeprogrammationCMW
    Rapporteur
    NdeVisaCMW
    DatedeVisaCMW
    typedepublication
    Ndepublication
    Datedepublication
    JournaldePublication
    DatedePublicationsurJournal
    Dureedepreparationdesoffres
    Ndouverture
    Datedouverture
    Ndevaluationdesoffres
    Datedevaluationdesoffres
    Ndelettredecommande
    Datedelettredecommande
    Datedattribution
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
    }
    
    created_at
    updated_at
  }
}
`;
export const deletCcharge = `
mutation 
deleteCcharge(
  $id: String!,) {
    deleteCcharge(id: $id){
    id
    NetIntituledelOperation
    }
}
`;
