export const SearchUs = `
query UsSearch($key: String){UsSearch(key:$key){
  id
  name
  status
  email
  created_at
  updated_at
}
}`;

export const Tusers = `
{
  users(first: 10, page: 1) {
    paginatorInfo {
     
      total
    }
    
  }
}
`;

export const createUser = `
mutation 
createUser(
  $name: String,
  $email: String,
  $status: String,
  $password: String,) {
    createUser(
      name: $name
      email:$email
      status: $status
      password: $password
  ){
    id
    name
    status
    email
    created_at
    updated_at
    
  }
}
`;
export const updateUser = `
mutation 
updateUser(
  $id: String,
  $name: String,
  $email: String,
  $status: String,
  $password: String,) {
    updateUser(
      id: $id
      name: $name
      email:$email
      status: $status
      password: $password
  ){
    id
    name
    status
    email
    created_at
    updated_at
    
  }
}
`;
