export const filesSearch = `
query FilesSearch(
    $column_name: String,
    $id: String){
        FilesSearch(
          column_name: $column_name
          id: $id
          ) {
            
            filepath
            author{
              name
            }
            
          }
    }
  `;
