import React,{useState} from 'react'
import { updateEntreprise} from '../../libs/entreprise'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import { filesSearch } from  "../../libs/files";
import FilesList from '../../components/FilesList';

const EntModif = (data) => {
  const [inputs, setInputs] = useState(data.post);
  const [files, setFiles] = useState([]);
  const [postid, setPostid] = useState("");
  const [showup, setShowup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showfiles, setShowFiles] = useState([]);
  const notify = (msg,type)=>store.addNotification({
    title: "succès",
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  }); 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    
    //console.log(inputs)
    create(inputs)

  }
  const create = async(post)=>{
    try {

      const author_id = localStorage.getItem("id")
      const graphqlQuery = {
        query: updateEntreprise,
        variables: {
          id: post.id,
        Entreprise: post.Entreprise,
      tel: post.tel,
      NIS: post.NIS,
      NIF: post.NIF,
        author_id: author_id,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          console.log(data)
          notify("MOdification Entreprise "+ data.data.updateEntreprise.Entreprise +" avec succés","success")
          setPostid(data.data.updateEntreprise.id)
          setShowup(true)
        })
        .catch((error) => {
          notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
        })

        
    } catch {
         
    }

    

    

  }
 
    return (
      <div className="content-page">
  <div className="content">
    {/* Start Content*/}
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                <li className="breadcrumb-item"><a href="javascript: void(0);">Entreprises</a></li>
                <li className="breadcrumb-item active">Creation</li>
              </ol>
            </div>
            <button type="button" className="btn btn-default waves-effect waves-light" onClick={data.onReset}>
  <span className="btn-label"><i className="mdi mdi-arrow-left-bold-outline
" /></span>Retour
</button>
            <h4 className="page-title">Creation d' Entreprise</h4>
          </div>
        </div>
      </div>     
      {/* end page title */} 
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
              <form onSubmit={handleSubmit} >
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">Entreprise</label>
                    <input type="text" 
                    name="Entreprise" 
                    value={inputs.Entreprise || "null"} 
                    onChange={handleChange}         
                    className="form-control" placeholder="N de PV d'Ouverture" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">Téléphone</label>
                    <input type="text" 
                    name="tel" 
                    value={inputs.tel || "null"} 
                    onChange={handleChange} 
                    className="form-control" placeholder="N de PV d'Ouverture" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">NIS</label>
                    <input type="text" 
                    name="NIS" 
                    value={inputs.NIS || "null"} 
                    onChange={handleChange} 
                    className="form-control" placeholder="N de PV d'Ouverture" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">NIF</label>
                    <input type="text" 
                    name="NIF" 
                    value={inputs.NIF || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de PV d'Ouverture" />
                  </div>
                  <div className="row mt-3">
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-warning waves-effect waves-light m-1" ><i className="fe-check-circle me-1" /> Modifier</button>
               </div>
              </div>
                </div> {/* end col*/}
                </form>
              </div>
            </div> {/* end card-body */}
          </div> {/* end card*/}
        </div> {/* end col*/}
      </div>
      {/* end row*/}
      {/* container */}
    </div> {/* container */}
  </div> {/* content */}
</div>
    )
}

export default EntModif
