import React from "react";
import { Link } from "react-router-dom";

const Lbare = (data) => {
  return (
    <div className="left-side-menu">
      <div className="h-100" data-simplebar>
        {/* User box */}
        {/*- Sidemenu */}
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className="menu-title">Navigation</li>
            <li>
              {" "}
              <Link to="/">
                {" "}
                <i className="mdi mdi-view-dashboard-outline" />{" "}
                <span> Tableau De Bord </span>{" "}
              </Link>
            </li>
            <li className="menu-title mt-2">GGMP</li>
            <li>
              {" "}
              <Link to="/Cahier-des-charges">
                {" "}
                <i className="mdi mdi-briefcase-check-outline" />{" "}
                <span> Cahier des charges </span>
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/Marche">
                {" "}
                <i className="mdi mdi-book-cog-outline" />{" "}
                <span> Marchés </span>
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/Convention">
                {" "}
                <i className="mdi mdi-handshake-outline" />{" "}
                <span> Conventions </span>
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/Avenant">
                {" "}
                <i className="mdi mdi-credit-card-refund-outline" />{" "}
                <span> Avenants </span>
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/Enttreprise">
                {" "}
                <i className="mdi mdi-card-account-details-outline" />{" "}
                <span> Entreprises </span>
              </Link>
            </li>
            {data.isAdmin && (
              <li id="cu">
                <Link to="/Utilisateur">
                  {" "}
                  <i className="mdi mdi-card-account-details-outline" />{" "}
                  <span> Utilisateurs </span>
                </Link>
              </li>
            )}
            <li className="menu-title mt-2">APPS</li>
            <li>
              {" "}
              <a href="#apps-chat1">
                {" "}
                <i className="mdi mdi-chat-minus-outline" />{" "}
                <span> Discussion </span>{" "}
              </a>{" "}
            </li>
            <li>
              {" "}
              <a href="#apps-calendar">
                {" "}
                <i className="mdi mdi-calendar-account-outline" />{" "}
                <span> Calandrier </span>{" "}
              </a>{" "}
            </li>
          </ul>
        </div>
        {/* End Sidebar */}
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default Lbare;
