import React,{useState,useEffect} from 'react'
import { updateCcharge} from '../../libs/ccharge'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import { filesSearch } from  "../../libs/files";
import FilesList from '../../components/FilesList';

const CcDetail = (data) => {
    const [inputs, setInputs] = useState(data.post);
    const [files, setFiles] = useState([]);
    const [postid, setPostid] = useState("");
    const [showup, setShowup] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showfiles, setShowFiles] = useState([]);
  
  
    const notify = (msg,type)=>store.addNotification({
      title: "succès",
      message: msg,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
        showIcon: true
      }
    }); 
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
      console.log(inputs)
    }
    const handleSubmit = (event) => {
      event.preventDefault();
    
      create(inputs)
  
    }
    const create = async(post)=>{
      try {
  
        const author_id = localStorage.getItem("id")
        const graphqlQuery = {
          query: updateCcharge,
          variables: {
          id: post.id,
          NetIntituledelOperation: post.NetIntituledelOperation,
          Intituleduprojet: post.Intituleduprojet,
          NduLot: post.NduLot,
          IntituleduLot: post.IntituleduLot,
          DatedeNotification: post.DatedeNotification,
          Datedinscription: post.Datedinscription,
          APInitiale: post.APInitiale,
          APactuelle: post.APactuelle,
          Cahierdescharges: post.Cahierdescharges,
          DatedepotCMW: post.DatedepotCMW,
          DatedeprogrammationCMW: post.DatedeprogrammationCMW,
          Rapporteur: post.Rapporteur,
          NdeVisaCMW: post.NdeVisaCMW,
          DatedeVisaCMW: post.DatedeVisaCMW,
          typedepublication: post.typedepublication,
          Ndepublication: post.Ndepublication,
          Datedepublication: post.Datedepublication,
          JournaldePublication: post.JournaldePublication,
          DatedePublicationsurJournal: post.DatedePublicationsurJournal,
          Dureedepreparationdesoffres: post.Dureedepreparationdesoffres,
          Ndouverture: post.Ndouverture,
          Datedouverture: post.Datedouverture,
          Ndevaluationdesoffres: post.Ndevaluationdesoffres,
          Datedevaluationdesoffres: post.Datedevaluationdesoffres,
          Ndelettredecommande: post.Ndelettredecommande,
          Datedelettredecommande: post.Datedelettredecommande,
          Datedattribution: post.Datedattribution,
          Observation: post.Observation,
          author_id: post.author.id,
          },
        };
        fetch(API, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
        "Content-Type": "application/json",
        Accept: "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then(async (result) => {
            const res = await result.json();
            return res;
          })
          .then((response) => {
            let data = response;
            console.log(data)
            notify("Creation Cahier de charge "+ data.data.updateCcharge.NetIntituledelOperation +" avec succés","success")
            setPostid(data.data.updateCcharge.id)
            setShowup(true)
          })
          .catch((error) => {
            notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
          })
  
          
      } catch {
           
      }
  
      
  
      
  
    }
    useEffect(() => {
      handleLoadF(data.post.id);
    }, []);
    const handleLoadF = (id) => {
      const graphqlQuery = {
        query: filesSearch,
        variables: {
          column_name: "ccharge_id",
          id: id,
        },
      };
      try {
    
        
        
        fetch(API, {
          method: "POST",
          headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then(async (result) => {
            const res = await result.json();
            return res;
          })
          .then((response) => {
            let data = response.data.FilesSearch;
            console.log("files:"+ data)
            setShowFiles(data);
           
          })
          .catch((error) => {
            
          })
    
          
      } catch {
           
      }
    };
   
    const handleFiles = (event) => {
      event.preventDefault();
     
      filesPost(files)
    }
    const filesPost= async (files) => {
      setUploading(true)
      var myHeaders = new Headers();
      const author_id = localStorage.getItem("id")
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem("ggmpToken"));
      
      var formdata = new FormData();
      formdata.append("operations", "{\n        \"query\": \"mutation Files($files: Upload!,$author_id:String!,$ccharge_id:String!) { Files(files: $files,author_id:$author_id,ccharge_id:$ccharge_id) }\",\n        \"variables\": {\n  \"files\": null,\n\"author_id\":\""+author_id+"\"\n ,\n\"ccharge_id\":\""+postid+"\"\n        }\n    }");
      formdata.append("map", "{\n        \"0\": [\"variables.files\"]}");
      formdata.append("0", files);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(API, requestOptions)
        .then(response => response.text())
        .then((result) => {
          notify("fichier envoyé avec succès","success")
          handleLoadF(postid)
          setUploading(false)
      })
        .catch((error) => { notify("Erreur: verifier votre connexion","danger")
        setUploading(false)
      });
    
  
    };
  
  
      return (
          <div className="content-page">
    <div className="content">
      {/* Start Content*/}
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                  <li className="breadcrumb-item"><a href="javascript: void(0);">Cahier Des Charges</a></li>
                  <li className="breadcrumb-item active">Modification</li>
                </ol>
              </div>
              <button type="button" className="btn btn-default waves-effect waves-light" onClick={data.onReset}>
    <span className="btn-label"><i className="mdi mdi-arrow-left-bold-outline
  " /></span>Retour
  </button>
  
              <h4 className="page-title">Detail de Cahier Des Charges</h4>
            </div>
          </div>
        </div> 
        {/* end page title */} 
        <div className="row" >
        <fieldset disabled="disabled">
          <div className="col-12">
            <div className="card">
              <form onSubmit={handleSubmit} >
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">N° et Intitulé de l'Opération</label>
                      <input type="text" className="form-control" placeholder="N° et Intitulé de l'Opération" 
                      name="NetIntituledelOperation" 
          value={inputs.NetIntituledelOperation } 
          onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">Intitulé du projet</label>
                      <input type="text"  className="form-control" placeholder="Intitulé du projet" 
                      name="Intituleduprojet" 
                      value={inputs.Intituleduprojet } 
                      onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">N du Lot</label>
                      <input name="NduLot" 
          value={inputs.NduLot } 
          onChange={handleChange} type="text"  className="form-control" placeholder="N du Lot" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname4" className="form-label">Intitulé du Lot</label>
                      <input name="IntituleduLot" 
          value={inputs.IntituleduLot } 
          onChange={handleChange} type="text"  className="form-control" placeholder="Intitulé du Lot" />
                    </div>
                    {/* Date View */}
                    <div className="mb-3">
                      <label className="form-label">Date de Notification</label>
                      <input name="DatedeNotification" 
          value={inputs.DatedeNotification || "2022-01-17"} 
          onChange={handleChange} type="date"  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    {/* Date View */}
                    <div className="mb-3">
                      <label className="form-label">Date d'inscription</label>
                      <input name="Datedinscription" 
          value={inputs.Datedinscription || "2022-01-17"} 
          onChange={handleChange} type="date"  className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">AP Initiale</label>
                      <input name="APInitiale" 
          value={inputs.APInitiale } 
          onChange={handleChange} type="text" id="AP Initiale" className="form-control" placeholder="AP Initiale" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">AP actuelle</label>
                      <input name="APactuelle" 
          value={inputs.APactuelle } 
          onChange={handleChange} type="text" id="AP actuelle" className="form-control" placeholder="AP actuelle" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Cahier des charges</label><input value={inputs.Cahierdescharges }  className="form-label" disabled /> <br />
                      <div className="form-check form-check-inline">
                        <input type="radio"  className="form-check-input"
                        name="Cahierdescharges" 
          value="Etude" 
          onChange={handleChange} />
                        <label className="form-check-label" htmlFor="customRadio1">Etude</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio"  className="form-check-input" 
                        name="Cahierdescharges" 
          value="Fourniture" 
          onChange={handleChange}  />
                        <label className="form-check-label" htmlFor="customRadio2">Fourniture</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio"  className="form-check-input"
                        name="Cahierdescharges" 
          value="Réalisation" 
          onChange={handleChange}   
          defaultChecked />
                        <label className="form-check-label" htmlFor="customRadio3">Réalisation</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date dépôt CMW</label>
                      <input name="DatedepotCMW" 
          value={inputs.DatedepotCMW} 
          onChange={handleChange} type="date" id="Date dépôt CMW" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date de programmation CMW</label>
                      <input name="DatedeprogrammationCMW" 
          value={inputs.DatedeprogrammationCMW || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date de programmation CMW" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">Rapporteur</label>
                      <input name="Rapporteur" 
          value={inputs.Rapporteur } 
          onChange={handleChange} type="text" id="Rapporteur" className="form-control" placeholder="Rapporteur" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">N de Visa CMW</label>
                      <input name="NdeVisaCMW" 
          value={inputs.NdeVisaCMW } 
          onChange={handleChange} type="text" id="N de Visa CMW" className="form-control" placeholder="N de Visa CMW" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date de Visa CMW</label>
                      <input name="DatedeVisaCMW" 
          value={inputs.DatedeVisaCMW || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date de Visa CMW" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname" className="form-label">N  de lettre de commande</label>
                      <input name="Ndelettredecommande" 
          value={inputs.Ndelettredecommande } 
          onChange={handleChange} type="text" id="N  de lettre de commande" className="form-control" placeholder="N  de lettre de commande" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date de lettre de commande</label>
                      <input name="Datedelettredecommande" 
          value={inputs.Datedelettredecommande || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date de lettre de commande" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                  </div> {/* end col*/}
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">type de publication</label><input value={inputs.typedepublication }  className="form-label" disabled />
                      <br />
                      <div className="form-check form-check-inline">
                        <input name="typedepublication" 
          value="Appel d offres" 
          onChange={handleChange} type="radio"  className="form-check-input" defaultValue="Appel d offres" />
                        <label className="form-check-label" htmlFor="customRadio7">Appel d offres</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input name="typedepublication" 
          value="Concours" 
          onChange={handleChange} type="radio"  className="form-check-input" defaultValue="Concours" />
                        <label className="form-check-label" htmlFor="customRadio8">Concours</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input name="typedepublication" 
          value="Consultations" 
          onChange={handleChange} type="radio"  className="form-check-input" defaultValue="Consultations" defaultChecked />
                        <label className="form-check-label" htmlFor="customRadio9">Consultations</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname3" className="form-label">N de publication</label>
                      <input name="Ndepublication" 
          value={inputs.Ndepublication } 
          onChange={handleChange} type="text" id="N de publication" className="form-control" placeholder="N de publication" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date de publication</label>
                      <input name="Datedepublication" 
          value={inputs.Datedepublication || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date de publication" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname3" className="form-label">Journal de Publication</label>
                      <input name="JournaldePublication" 
          value={inputs.JournaldePublication } 
          onChange={handleChange} type="text" id="Journal de Publication" className="form-control" placeholder="Journal de Publication" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date de Publication sur Journal</label>
                      <input name="DatedePublicationsurJournal" 
          value={inputs.DatedePublicationsurJournal || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date de Publication sur Journal" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname3" className="form-label">Durée de préparation des offres</label>
                      <input name="Dureedepreparationdesoffres" 
          value={inputs.Dureedepreparationdesoffres } 
          onChange={handleChange} type="text" id="Durée de préparation des offres" className="form-control" placeholder="Durée de préparation des offres" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname3" className="form-label">N d'ouverture</label>
                      <input name="Ndouverture" 
          value={inputs.Ndouverture} 
          onChange={handleChange} type="text" id="N d'ouverture" className="form-control" placeholder="N d'ouverture" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date d'ouverture</label>
                      <input name="Datedouverture" 
          value={inputs.Datedouverture || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date d'ouverture" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="projectname3" className="form-label">N d'évaluation des offres</label>
                      <input name="Ndevaluationdesoffres" 
          value={inputs.Ndevaluationdesoffres } 
          onChange={handleChange} type="text" id="N d'évaluation des offres" className="form-control" placeholder="N d'évaluation des offres" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date d'évaluation des offres</label>
                      <input name="Datedevaluationdesoffres" 
          value={inputs.Datedevaluationdesoffres || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date d'évaluation des offres" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date d'attribution</label>
                      <input name="Datedattribution" 
          value={inputs.Datedattribution || "2022-01-17"} 
          onChange={handleChange} type="date" id="Date d'attribution" className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="project-overview" className="form-label">Observation</label>
                      <textarea name="Observation" 
          value={inputs.Observation } 
          onChange={handleChange} className="form-control" id="Observation" rows={5} placeholder="entrez votre observation" defaultValue={"null"} />
                    </div>												
                  </div> {/* end col*/}
                </div>
                {/* end row */}
               
              </div> {/* end card-body */}
              </form>
            </div> {/* end card*/}
          </div> {/* end col*/}
          </fieldset>
        </div>
        
        <div className="col-xl-12" id="upload-ccharge">
        
          <div className="col-12 text-center">
    <div className="mt-3">
      <h5 className="mb-2">Fichiers</h5>
      <div className="row mx-n1 g-0">
      <FilesList 
        files={showfiles}
        />
      </div> 
    </div>
  </div>
  
          
        </div>
        
         
      </div> {/* content */}
    </div>
  </div>
      )
}

export default CcDetail
