import React, { useState, useEffect } from "react";
import { SearchCC } from "../libs/ccharge";
import { SearchM } from "../libs/marche";
import { SearchCo } from "../libs/convention";
import { SearchAv } from "../libs/avenant";
import { API } from "../libs/costants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
  const [ccharge, setCcharge] = useState([]);
  const [marche, setMarche] = useState([]);
  const [convention, setConvention] = useState([]);
  const [avenant, setAvenant] = useState([]);
  const [chartData, setchartData] = useState([]);
  useEffect(() => {
    loadCcharges("");
  }, []);
  const loadCcharges = async (key) => {
    try {
      const graphqlQuery = {
        query: SearchCC,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.CcSearch;
          setCcharge(data);
        });
    } catch {}
    try {
      const graphqlQuery = {
        query: SearchM,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.MSearch;
          setMarche(data);
        });
    } catch {}
    try {
      const graphqlQuery = {
        query: SearchCo,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.CoSearch;
          setConvention(data);
        });
    } catch {}
    try {
      const graphqlQuery = {
        query: SearchAv,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.ASearch;
          setAvenant(data);
        });
    } catch {}
  };
  const janvier = (data) => {
    const edj = new Date("2022-01-01").getTime();
    const sdj = new Date("2022-01-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const fevrier = (data) => {
    const edf = new Date("2022-02-01").getTime();
    const sdf = new Date("2022-02-30").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edf < time && time < sdf;
    });
    //var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const mars = (data) => {
    const edj = new Date("2022-03-01").getTime();
    const sdj = new Date("2022-03-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const avril = (data) => {
    const edj = new Date("2022-04-01").getTime();
    const sdj = new Date("2022-04-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const mais = (data) => {
    const edj = new Date("2022-05-01").getTime();
    const sdj = new Date("2022-05-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const juin = (data) => {
    const edj = new Date("2022-06-01").getTime();
    const sdj = new Date("2022-06-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const juil = (data) => {
    const edj = new Date("2022-07-01").getTime();
    const sdj = new Date("2022-07-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const out = (data) => {
    const edj = new Date("2022-08-01").getTime();
    const sdj = new Date("2022-08-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const sept = (data) => {
    const edj = new Date("2022-09-01").getTime();
    const sdj = new Date("2022-09-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const oct = (data) => {
    const edj = new Date("2022-10-01").getTime();
    const sdj = new Date("2022-10-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const nov = (data) => {
    const edj = new Date("2022-11-01").getTime();
    const sdj = new Date("2022-11-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };
  const dec = (data) => {
    const edj = new Date("2022-12-01").getTime();
    const sdj = new Date("2022-12-31").getTime();
    const janvier = data.filter((d) => {
      var time = new Date(d.created_at).getTime();
      return edj < time && time < sdj;
    });
    var name = "ccjanvier";
    var value = janvier.length;
    //setchartData((values) => ({ ...values, [name]: value }));
    return value;
  };

  const data = {
    labels: [
      "janv",
      "févr",
      "mars",
      "avr",
      "mai",
      "juin",
      "juill",
      "août",
      "sept",
      "oct",
      "nov",
      "déc",
    ],
    datasets: [
      {
        label: "Cahier Des Charges",
        data: [
          janvier(ccharge),
          fevrier(ccharge),
          mars(ccharge),
          avril(ccharge),
          mais(ccharge),
          juin(ccharge),
          juil(ccharge),
          out(ccharge),
          sept(ccharge),
          oct(ccharge),
          nov(ccharge),
          dec(ccharge),
        ],
        backgroundColor: "#4a81d4",
      },
      {
        label: "Marchés",
        data: [
          janvier(marche),
          fevrier(marche),
          mars(marche),
          avril(marche),
          mais(marche),
          juin(marche),
          juil(marche),
          out(marche),
          sept(marche),
          oct(marche),
          nov(marche),
          dec(marche),
        ],
        backgroundColor: "#1abc9c",
      },
      {
        label: "Conventions",
        data: [
          janvier(convention),
          fevrier(convention),
          mars(convention),
          avril(convention),
          mais(convention),
          juin(convention),
          juil(convention),
          out(convention),
          sept(convention),
          oct(convention),
          nov(convention),
          dec(convention),
        ],
        backgroundColor: "#4fc6e1",
      },
      {
        label: "Avenants",
        data: [
          janvier(avenant),
          fevrier(avenant),
          mars(avenant),
          avril(avenant),
          mais(avenant),
          juin(avenant),
          juil(avenant),
          out(avenant),
          sept(avenant),
          oct(avenant),
          nov(avenant),
          dec(avenant),
        ],
        backgroundColor: "#f7b84b",
      },
    ],
  };
  return (
    <div>
      <div className="content-page">
        <div className="content">
          {/* Start Content*/}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right"></div>
                  <h4 className="page-title">Dashboard</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xl-3">
                <div className="widget-rounded-circle card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <div className="avatar-lg rounded-circle bg-primary border-primary border shadow">
                          <i className="mdi mdi-briefcase-check-outline font-22 avatar-title text-white" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end">
                          <h3 className="text-dark mt-1">
                            <span data-plugin="counterup">
                              {ccharge.length}
                            </span>
                          </h3>
                          <p className="text-muted mb-1 text-truncate">
                            Total Cahiesr des charges
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end row*/}
                  </div>
                </div>{" "}
                {/* end widget-rounded-circle*/}
              </div>{" "}
              {/* end col*/}
              <div className="col-md-6 col-xl-3">
                <div className="widget-rounded-circle card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <div className="avatar-lg rounded-circle bg-success border-success border shadow">
                          <i className="mdi mdi-book-cog-outline font-22 avatar-title text-white" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end">
                          <h3 className="text-dark mt-1">
                            <span data-plugin="counterup">{marche.length}</span>
                          </h3>
                          <p className="text-muted mb-1 text-truncate">
                            Total Des Marchés
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end row*/}
                  </div>
                </div>{" "}
                {/* end widget-rounded-circle*/}
              </div>{" "}
              {/* end col*/}
              <div className="col-md-6 col-xl-3">
                <div className="widget-rounded-circle card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <div className="avatar-lg rounded-circle bg-info border-info border shadow">
                          <i className="mdi mdi-handshake-outline font-22 avatar-title text-white" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end">
                          <h3 className="text-dark mt-1">
                            <span data-plugin="counterup">
                              {convention.length}
                            </span>
                          </h3>
                          <p className="text-muted mb-1 text-truncate">
                            Total Des Conventions
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end row*/}
                  </div>
                </div>{" "}
                {/* end widget-rounded-circle*/}
              </div>{" "}
              {/* end col*/}
              <div className="col-md-6 col-xl-3">
                <div className="widget-rounded-circle card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <div className="avatar-lg rounded-circle bg-warning border-warning border shadow">
                          <i className="mdi mdi-credit-card-refund-outline font-22 avatar-title text-white" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end">
                          <h3 className="text-dark mt-1">
                            <span data-plugin="counterup">
                              {avenant.length}
                            </span>
                          </h3>
                          <p className="text-muted mb-1 text-truncate">
                            Total Des Avenants
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end row*/}
                  </div>
                </div>{" "}
                {/* end widget-rounded-circle*/}
              </div>{" "}
              {/* end col*/}
            </div>

            <div classname="row">
              <div classname="col-lg-4">
                <div classname="card">
                  <div classname="card-body">
                    <Bar data={data} />
                  </div>
                </div>
              </div>
              <div classname="col-lg-8">
                <div classname="card">
                  <div classname="card-body pb-2">
                    {"totale c charges" + +"janvier data" + chartData.ccjanvier}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* container */}
        </div>{" "}
        {/* content */}
      </div>
    </div>
  );
};

export default Home;
