export const SearchAv = `
query ASearch($key: String){ASearch(key:$key){
  id
  NetIntituledelOperation
  Intituleduprojet
  NduLot
  IntituleduLot
  APInitiale
  APActuelle
  EntrepriseM
  MontantduMarcheenTTC
  DelaiduMarche
  typedeMarche
  typedAvenant
  MontantdelavenantenTTC
  NouveauMontantduMarcheenTTC
  NouveauDelai
  NetVisadeMarche
  NdODSdemarrage
  DatedODSdemarrage
  NdODSdarret
  DatedODSdarret
  NdODSdereprise
  DatedODSdereprise
  PVdereceptionprovisoire
  DatedepotCMW
  DatedeprogrammationCMW
  NdeVisaCMW
  DatedeVisaCMW
  Ndengagement
  Datedengagement
  DateSignaturedelavenant
  NdelODS
  DatedelODS
  Observation
  author {
    id
    name
    status
    email
    created_at
    updated_at
   
  }
  marche {
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    NetVisaduCahierdesCharges
    Choixdeloffre
    Ndeprocedure
    Typedeprocedure
    DatedePublication
    JournaldePublication
    DureedePreparationdesOffres
    NdePVdOuverture
    DatedePVdOuverture
    NdePVdevaluation
    DatedePVdevaluation
    DatedAttribution
    journaldAttribution
    DatedepotCMW
    Rapporteur
    Datedeprogrammation
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagemen
    Datedengagemen
    DateSignaturedeMarche
    NdelODS
    DatedelODS
    Observation
    
    created_at
    updated_at
  }
  convention {
    id
    NdelaConvention
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    Choixdeloffre
    Nprocedure
    typedeprocedure
    DatedePublication
    DureedePreparationdesOffres
    NPVdOuverture
    DatedePVdOuverture
    NPVdevaluation
    DatedePVdevaluation
    DatedAttribution
    JournaleAttribution
    DatedepotauCF
    Ndengagement
    Datedengagement
    DateSignaturedelaconvention
    NdelODS
    DatedelODS
    Observation
   
    created_at
    updated_at
  }
  entreprise {
    id
    Entreprise
    tel
    NIS
    NIF
    
    created_at
    updated_at
  }
  created_at
  updated_at
}
}`;

export const TAvenant = `
{
  avenants(first: 10, page: 1) {
    paginatorInfo {
     
      total
    }
    
  }
}
`;

export const createAvenant = `
mutation 
createAvenant(
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $APInitiale: String,
  $APActuelle: String,
  $EntrepriseM: String,
  $MontantduMarcheenTTC: String,
  $DelaiduMarche: String,
  $typedeMarche: String,
  $typedAvenant: String,
  $MontantdelavenantenTTC: String,
  $NouveauMontantduMarcheenTTC: String,
  $NouveauDelai: String,
  $NetVisadeMarche: String,
  $NdODSdemarrage: String,
  $DatedODSdemarrage: String,
  $NdODSdarret: String,
  $DatedODSdarret: String,
  $NdODSdereprise: String,
  $DatedODSdereprise: String,
  $PVdereceptionprovisoire: String,
  $DatedepotCMW: String,
  $DatedeprogrammationCMW: String,
  $NdeVisaCMW: String,
  $DatedeVisaCMW: String,
  $Ndengagement: String,
  $Datedengagement: String,
  $DateSignaturedelavenant: String,
  $NdelODS: String,
  $DatedelODS: String,
  $Observation: String,
  $author_id: String,
  $marche_id: String,
  $convention_id: String,) {
    createAvenant(
      NetIntituledelOperation:$NetIntituledelOperation
      Intituleduprojet:      $Intituleduprojet
      NduLot:      $NduLot
      IntituleduLot:      $IntituleduLot
      APInitiale:      $APInitiale
      APActuelle:      $APActuelle
      EntrepriseM:      $EntrepriseM
      MontantduMarcheenTTC:      $MontantduMarcheenTTC
      DelaiduMarche:      $DelaiduMarche
      typedeMarche:      $typedeMarche
      typedAvenant:      $typedAvenant
      MontantdelavenantenTTC:      $MontantdelavenantenTTC
      NouveauMontantduMarcheenTTC:      $NouveauMontantduMarcheenTTC
      NouveauDelai:      $NouveauDelai
      NetVisadeMarche:      $NetVisadeMarche
      NdODSdemarrage:      $NdODSdemarrage
      DatedODSdemarrage:      $DatedODSdemarrage
      NdODSdarret:      $NdODSdarret
      DatedODSdarret:      $DatedODSdarret
      NdODSdereprise:      $NdODSdereprise
      DatedODSdereprise:      $DatedODSdereprise
      PVdereceptionprovisoire:      $PVdereceptionprovisoire
      DatedepotCMW:      $DatedepotCMW
      DatedeprogrammationCMW:      $DatedeprogrammationCMW
      NdeVisaCMW:      $NdeVisaCMW
      DatedeVisaCMW:      $DatedeVisaCMW
      Ndengagement:      $Ndengagement
      Datedengagement:      $Datedengagement
      DateSignaturedelavenant:      $DateSignaturedelavenant
      NdelODS:      $NdelODS
      DatedelODS:      $DatedelODS
      Observation:      $Observation
      author_id:      $author_id
      marche_id:      $marche_id
      convention_id:      $convention_id
  ){
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    APInitiale
    APActuelle
    EntrepriseM
    MontantduMarcheenTTC
    DelaiduMarche
    typedeMarche
    typedAvenant
    MontantdelavenantenTTC
    NouveauMontantduMarcheenTTC
    NouveauDelai
    NetVisadeMarche
    NdODSdemarrage
    DatedODSdemarrage
    NdODSdarret
    DatedODSdarret
    NdODSdereprise
    DatedODSdereprise
    PVdereceptionprovisoire
    DatedepotCMW
    DatedeprogrammationCMW
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagement
    Datedengagement
    DateSignaturedelavenant
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
      
    }
    marche {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APActuelle
      EntrepriseM
      MontantenTTC
      Delai
      Cahierdescharges
      NetVisaduCahierdesCharges
      Choixdeloffre
      Ndeprocedure
      Typedeprocedure
      DatedePublication
      JournaldePublication
      DureedePreparationdesOffres
      NdePVdOuverture
      DatedePVdOuverture
      NdePVdevaluation
      DatedePVdevaluation
      DatedAttribution
      journaldAttribution
      DatedepotCMW
      Rapporteur
      Datedeprogrammation
      NdeVisaCMW
      DatedeVisaCMW
      Ndengagemen
      Datedengagemen
      DateSignaturedeMarche
      NdelODS
      DatedelODS
      Observation
      
      created_at
      updated_at
    }
    convention {
      id
      NdelaConvention
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APActuelle
      EntrepriseM
      MontantenTTC
      Delai
      Cahierdescharges
      Choixdeloffre
      Nprocedure
      typedeprocedure
      DatedePublication
      DureedePreparationdesOffres
      NPVdOuverture
      DatedePVdOuverture
      NPVdevaluation
      DatedePVdevaluation
      DatedAttribution
      JournaleAttribution
      DatedepotauCF
      Ndengagement
      Datedengagement
      DateSignaturedelaconvention
      NdelODS
      DatedelODS
      Observation
      
      created_at
      updated_at
    }
    
    created_at
    updated_at
  }
}
`;
export const updateAvenant = `
mutation 
updateAvenant(
  $id: String,
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $APInitiale: String,
  $APActuelle: String,
  $EntrepriseM: String,
  $MontantduMarcheenTTC: String,
  $DelaiduMarche: String,
  $typedeMarche: String,
  $typedAvenant: String,
  $MontantdelavenantenTTC: String,
  $NouveauMontantduMarcheenTTC: String,
  $NouveauDelai: String,
  $NetVisadeMarche: String,
  $NdODSdemarrage: String,
  $DatedODSdemarrage: String,
  $NdODSdarret: String,
  $DatedODSdarret: String,
  $NdODSdereprise: String,
  $DatedODSdereprise: String,
  $PVdereceptionprovisoire: String,
  $DatedepotCMW: String,
  $DatedeprogrammationCMW: String,
  $NdeVisaCMW: String,
  $DatedeVisaCMW: String,
  $Ndengagement: String,
  $Datedengagement: String,
  $DateSignaturedelavenant: String,
  $NdelODS: String,
  $DatedelODS: String,
  $Observation: String,
  $author_id: String,
  $marche_id: String,
  $convention_id: String,) {
    updateAvenant(
      id: $id
      NetIntituledelOperation:$NetIntituledelOperation
      Intituleduprojet:      $Intituleduprojet
      NduLot:      $NduLot
      IntituleduLot:      $IntituleduLot
      APInitiale:      $APInitiale
      APActuelle:      $APActuelle
      EntrepriseM:      $EntrepriseM
      MontantduMarcheenTTC:      $MontantduMarcheenTTC
      DelaiduMarche:      $DelaiduMarche
      typedeMarche:      $typedeMarche
      typedAvenant:      $typedAvenant
      MontantdelavenantenTTC:      $MontantdelavenantenTTC
      NouveauMontantduMarcheenTTC:      $NouveauMontantduMarcheenTTC
      NouveauDelai:      $NouveauDelai
      NetVisadeMarche:      $NetVisadeMarche
      NdODSdemarrage:      $NdODSdemarrage
      DatedODSdemarrage:      $DatedODSdemarrage
      NdODSdarret:      $NdODSdarret
      DatedODSdarret:      $DatedODSdarret
      NdODSdereprise:      $NdODSdereprise
      DatedODSdereprise:      $DatedODSdereprise
      PVdereceptionprovisoire:      $PVdereceptionprovisoire
      DatedepotCMW:      $DatedepotCMW
      DatedeprogrammationCMW:      $DatedeprogrammationCMW
      NdeVisaCMW:      $NdeVisaCMW
      DatedeVisaCMW:      $DatedeVisaCMW
      Ndengagement:      $Ndengagement
      Datedengagement:      $Datedengagement
      DateSignaturedelavenant:      $DateSignaturedelavenant
      NdelODS:      $NdelODS
      DatedelODS:      $DatedelODS
      Observation:      $Observation
      author_id:      $author_id
      marche_id:      $marche_id
      convention_id:      $convention_id
  ){
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    APInitiale
    APActuelle
    EntrepriseM
    MontantduMarcheenTTC
    DelaiduMarche
    typedeMarche
    typedAvenant
    MontantdelavenantenTTC
    NouveauMontantduMarcheenTTC
    NouveauDelai
    NetVisadeMarche
    NdODSdemarrage
    DatedODSdemarrage
    NdODSdarret
    DatedODSdarret
    NdODSdereprise
    DatedODSdereprise
    PVdereceptionprovisoire
    DatedepotCMW
    DatedeprogrammationCMW
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagement
    Datedengagement
    DateSignaturedelavenant
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
      
    }
    marche {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APActuelle
      EntrepriseM
      MontantenTTC
      Delai
      Cahierdescharges
      NetVisaduCahierdesCharges
      Choixdeloffre
      Ndeprocedure
      Typedeprocedure
      DatedePublication
      JournaldePublication
      DureedePreparationdesOffres
      NdePVdOuverture
      DatedePVdOuverture
      NdePVdevaluation
      DatedePVdevaluation
      DatedAttribution
      journaldAttribution
      DatedepotCMW
      Rapporteur
      Datedeprogrammation
      NdeVisaCMW
      DatedeVisaCMW
      Ndengagemen
      Datedengagemen
      DateSignaturedeMarche
      NdelODS
      DatedelODS
      Observation
      
      created_at
      updated_at
    }
    convention {
      id
      NdelaConvention
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APActuelle
      EntrepriseM
      MontantenTTC
      Delai
      Cahierdescharges
      Choixdeloffre
      Nprocedure
      typedeprocedure
      DatedePublication
      DureedePreparationdesOffres
      NPVdOuverture
      DatedePVdOuverture
      NPVdevaluation
      DatedePVdevaluation
      DatedAttribution
      JournaleAttribution
      DatedepotauCF
      Ndengagement
      Datedengagement
      DateSignaturedelaconvention
      NdelODS
      DatedelODS
      Observation
      
      created_at
      updated_at
    }
    
    created_at
    updated_at
  }
}
`;
export const deletAvenant = `
mutation 
deleteAvenant(
  $id: String!,) {
    deleteAvenant(id: $id){
    id
    NetIntituledelOperation
    }
}
`;
