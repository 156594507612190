import React from 'react'
import {Link} from 'react-router-dom'

const CcListitem = (data) => {
  
    return (
      
        <div className="col-lg-4">
  <div className="card project-box border-success border mb-3">
  <div className="card-header bg-secondary text-white">
  {data.isAdmin &&  <div className="dropdown float-end">
        <a  className="dropdown-toggle card-drop arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
        </a>
        <div className="dropdown-menu dropdown-menu-end">
        <Link className="dropdown-item" onClick={(e) => data.onModif(data.post)}>Modified</Link>
          <Link className="dropdown-item" onClick={(e) => data.onDelet(data.post.id)}>Suprimé</Link>
        </div>
      </div> }
      <h4 className="mt-0"><a href="project-detail.html" className="text-white">{data.post.NetIntituledelOperation}</a></h4>
      <p className="text-muted text-uppercase"><i className="mdi mdi-account-circle" /> <small>{data.post.author.name}</small></p>
      </div>
    <div className="card-body">
      
      {/* Desc*/}
      <p className="text-muted font-13 mb-3 sp-line-2">{data.post.Observation}<br />
<Link  className="fw-bold text-muted" onClick={(e) => data.onView(data.post)}>Deatail Cahier de charge</Link>
      </p>
      
    </div>
  </div> {/* end card box*/}
</div>

    )
}

export default CcListitem
