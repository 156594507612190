import React,{useState,useEffect, Fragment} from 'react'
import { updateAvenant} from '../../libs/avenant'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';
import { filesSearch } from  "../../libs/files";
import FilesList from '../../components/FilesList';
import {SearchM} from '../../libs/marche'
import {SearchCo} from '../../libs/convention'
import {SearchEnt} from '../../libs/entreprise'
import Loading from '../../components/Loading';

const AvDetail = (data) => {
  const [entreprises, setEntreprises] = useState([]);
  const [ccharge, setCcharge] = useState([]);
  const [inputs, setInputs] = useState(data.post);
  const [files, setFiles] = useState([]);
  const [postid, setPostid] = useState("");
  const [showup, setShowup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showfiles, setShowFiles] = useState([]);
  const [loading, setloading] = useState(false);
  const [marche, setMarche] = useState([]);
  const [convention, setConvention] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);


  const notify = (msg,type)=>store.addNotification({
    title: "succès",
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  }); 
  const validate = (values) => {
    const errors = {};
   
    if (!values.convention_id) {
      errors.convention = notify("convention is required!","danger");
    }
    if (!values.EntrepriseM) {
      errors.Entreprise = notify("Entreprise is required!","danger");
    } 
    if (!values.marche_id) {
      errors.marche = notify("marche is required!","danger");
    }
    
    
    return errors;
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    console.log(inputs)
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(inputs));
    setIsSubmit(true);
    

  }
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      create(inputs);
    }
  }, [formErrors]);
  const create = async(post)=>{
    try {

      const author_id = localStorage.getItem("id")
      const graphqlQuery = {
        query: updateAvenant,
        variables: {
        id: post.id,
        NetIntituledelOperation: post.NetIntituledelOperation,
Intituleduprojet: post.Intituleduprojet,
NduLot: post.NduLot,
IntituleduLot: post.IntituleduLot,
APInitiale: post.APInitiale,
APActuelle: post.APActuelle,
EntrepriseM: post.EntrepriseM,
MontantduMarcheenTTC: post.MontantduMarcheenTTC,
DelaiduMarche: post.DelaiduMarche,
typedeMarche: post.typedeMarche,
typedAvenant: post.typedAvenant,
MontantdelavenantenTTC: post.MontantdelavenantenTTC,
NouveauMontantduMarcheenTTC: post.NouveauMontantduMarcheenTTC,
NouveauDelai: post.NouveauDelai,
NetVisadeMarche: post.NetVisadeMarche,
NdODSdemarrage: post.NdODSdemarrage,
DatedODSdemarrage: post.DatedODSdemarrage,
NdODSdarret: post.NdODSdarret,
DatedODSdarret: post.DatedODSdarret,
NdODSdereprise: post.NdODSdereprise,
DatedODSdereprise: post.DatedODSdereprise,
PVdereceptionprovisoire: post.PVdereceptionprovisoire,
DatedepotCMW: post.DatedepotCMW,
DatedeprogrammationCMW: post.DatedeprogrammationCMW,
NdeVisaCMW: post.NdeVisaCMW,
DatedeVisaCMW: post.DatedeVisaCMW,
Ndengagement: post.Ndengagement,
Datedengagement: post.Datedengagement,
DateSignaturedelavenant: post.DateSignaturedelavenant,
NdelODS: post.NdelODS,
DatedelODS: post.DatedelODS,
Observation: post.Observation,
marche_id: post.marche_id,
convention_id: post.convention_id,
author_id: post.author_id,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          console.log(data)
          notify("Creation Cahier de charge "+ data.data.updateAvenant.NetIntituledelOperation+" avec succés","success")
          setPostid(data.data.updateAvenant.id)
          setShowup(true)
        })
        .catch((error) => {
          notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
        })

        
    } catch {
         
    }

    

    

  }
  useEffect(() => {
   
      handleLoadF(data.post.id);
   
    try {
      setloading(true)
      
      const key = " "
      const graphqlQuery = {
        query: SearchM,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.MSearch;
          
          setMarche(data);
          
          setloading(false)
        });
    } catch {}
    try {
      setloading(true)
      const key = " "
      const graphqlQuery = {
        query: SearchCo,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.CoSearch;
          
          setConvention(data);
          console.log(convention)
          setloading(false)
        });
    } catch {}
    try {
      setloading(true)
      const key = ""
      const graphqlQuery = {
        query: SearchEnt,
        variables: {
          key: key,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.EntSearch;
          setEntreprises(data);
          setloading(false)
        });
    } catch {}
  }, []);
  const handleLoadF = (id) => {
    
    try {
      const graphqlQuery = {
        query: filesSearch,
        variables: {
          column_name: "avenant_id",
          id: id,
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response.data.FilesSearch;
          console.log("files:"+ data)
          setShowFiles(data);
         
        })
        .catch((error) => {
          
        })
  
        
    } catch {
         
    }
  };
 
  const handleFiles = (event) => {
    event.preventDefault();
   
    filesPost(files)
  }
  const filesPost= async (files) => {
    setUploading(true)
    var myHeaders = new Headers();
    const author_id = localStorage.getItem("id")
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("ggmpToken"));
    
    var formdata = new FormData();
    const operations = { query: `
    mutation files($file:Upload!) {
      Files(
        files: $file
        author_id: ${author_id}
        ccharge_id: 0
        marche_id: 0
        convention_id: 0
        avenant_id: ${postid}
      )
    }
  `,
  variables: {
    file: [null],
  },
  } ;
    const map = { 
      '0': ['variables.file'],
    };
    formdata.append("operations", JSON.stringify(operations))
    formdata.append("map", JSON.stringify(map));
    formdata.append("0", files);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(API, requestOptions)
      .then(response => response.text())
      .then((result) => {
        notify("fichier envoyé avec succès","success")
        handleLoadF(postid)
        setUploading(false)
    })
      .catch((error) => { notify("Erreur: verifier votre connexion","danger")
      setUploading(false)
    });
  

  };
  
    return (
      <div className="content-page">
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                    <li className="breadcrumb-item"><a href="javascript: void(0);">Avenants</a></li>
                    <li className="breadcrumb-item active">Detail</li>
                  </ol>
                </div>
                <button type="button" className="btn btn-default waves-effect waves-light" onClick={data.onReset}>
  <span className="btn-label"><i className="mdi mdi-arrow-left-bold-outline
" /></span>Retour
</button>
                <h4 className="page-title">Detail d'Avenant</h4>
              </div>
            </div>
          </div>
          {!loading &&
      
      <div className="row">
        <div className="col-12">
          <div className="card">
          <fieldset disabled="disabled">
          <form onSubmit={handleSubmit} >
            <div className="card-body">
              <div className="row">
                <div className="col-xl-6">
                <div className="mb-3">
                  <label htmlFor="project-overview" className="form-label">Marché</label><input value={inputs.marche.NetIntituledelOperation }  className="form-label" disabled /> <br/>
                  
                  </div>
                  <div className="mb-3">
                  <label htmlFor="project-overview" className="form-label">Convention</label><input value={inputs.convention.NdelaConvention }  className="form-label" disabled /> <br/>
                    
                 
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">N° et Intitulé de
                      l'Opération</label>
                    <input type="text" 
                    name="NetIntituledelOperation" 
                    value={inputs.NetIntituledelOperation|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N° et Intitulé de l'Opération" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">Intitulé du projet</label>
                    <input type="text" 
                    name="Intituleduprojet" 
                    value={inputs.Intituleduprojet|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Intitulé du projet" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname" className="form-label">N du Lot</label>
                    <input type="text" 
                    name="NduLot" 
                    value={inputs.NduLot|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N du Lot" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname4" className="form-label">Intitulé du Lot</label>
                    <input type="text" 
                    name="IntituleduLot" 
                    value={inputs.IntituleduLot|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Intitulé du Lot" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname4" className="form-label">AP initiale</label>
                    <input type="text" 
                    name="APInitiale" 
                    value={inputs.APInitiale|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="AP initiale" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname4" className="form-label">AP atcuelle</label>
                    <input type="text" 
                    name="APActuelle" 
                    value={inputs.APActuelle|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="AP atcuelle" />
                  </div>
                  <div>
                    <label htmlFor="project-overview" className="form-label">Entreprises</label><input value={inputs.entreprise.Entreprise}  className="form-label" disabled /> <br/>
                   
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Montant du Marché en TTC</label>
                    <input type="text" 
                    name="MontantduMarcheenTTC" 
                    value={inputs.MontantduMarcheenTTC|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Montant du Marché en TTC" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Délai du Marché</label>
                    <input type="text" 
                    name="DelaiduMarche" 
                    value={inputs.DelaiduMarche|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Délai du Marché" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Type de Marché</label> <input value={inputs.typedeMarche}  className="form-label" disabled /> <br/>
                  
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Type d'Avenant</label> <input value={inputs.typedAvenant}  className="form-label" disabled /> <br/>
                 
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Montant de l'avenant en TTC</label>
                    <input type="text" 
                    name="MontantdelavenantenTTC" 
                    value={inputs.MontantdelavenantenTTC|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Montant de l'avenant en TTC" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Montant des avenants précédents</label>
                    <input type="text" 
                     name="MontantdelavenantenTTC" 
                     value={inputs.MontantdelavenantenTTC|| "null"} 
                     onChange={handleChange}
                    className="form-control" placeholder="Montant des avenants précédents" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nouveau Montant du Marché en TTC</label>
                    <input type="number" 
                    name="NouveauMontantduMarcheenTTC" 
                    value={inputs.NouveauMontantduMarcheenTTC|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Nouveau Montant du Marché en TTC" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nouveau Délai</label>
                    <input type="text" 
                    name="NouveauDelai" 
                    value={inputs.NouveauDelai|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="Nouveau Délai" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">N et Visa de Marché</label>
                    <input type="text" 
                    name="NetVisadeMarche" 
                    value={inputs.NetVisadeMarche|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N et Visa de Marché" />
                  </div>
                </div> {/* end col*/}
                <div className="col-xl-6">
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N d'ODS démarrage</label>
                    <input type="number" 
                    name="NdODSdemarrage" 
                    value={inputs.NdODSdemarrage|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N d'ODS  démarrage" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date d'ODS démarrage</label>
                    <input type="date" 
                    name="DatedODSdemarrage" 
                    value={inputs.DatedODSdemarrage || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N d'ODS d'arrêt</label>
                    <input type="text" 
                    name="NdODSdarret" 
                    value={inputs.NdODSdarret|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N d'ODS d'arrêt" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date d'ODS d'arrêt</label>
                    <input type="date"
                     name="DatedODSdarret" 
                    value={inputs.DatedODSdarret || "2022-01-17"} 
                    onChange={handleChange} 
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N d'ODS de reprise</label>
                    <input type="text" 
                    name="NdODSdereprise" 
                    value={inputs.NdODSdereprise|| "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N d'ODS de reprise" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date d'ODS de reprise</label>
                    <input type="date" 
                    name="DatedODSdereprise" 
                    value={inputs.DatedODSdereprise || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">PV de réception provisoire</label>
                    <input type="text" 
                    name="PVdereceptionprovisoire" 
                    value={inputs.PVdereceptionprovisoire|| "null"} 
                    onChange={handleChange}
                    className="form-control"  />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date dépôt CMW</label>
                    <input type="date" 
                    name="DatedepotCMW" 
                    value={inputs.DatedepotCMW || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de programmation CMW</label>
                    <input type="text" 
                    name="DatedeprogrammationCMW" 
                    value={inputs.DatedeprogrammationCMW || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N de Visa CMW</label>
                    <input type="text" 
                    name="NdeVisaCMW" 
                    value={inputs.NdeVisaCMW || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de Visa CMW" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de Visa CMW</label>
                    <input type="date" 
                    name="DatedeVisaCMW" 
                    value={inputs.DatedeVisaCMW || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N d'engagement</label>
                    <input type="text" 
                    name="Ndengagement" 
                    value={inputs.Ndengagement || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N d'engagement" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date d'engagement</label>
                    <input type="date" 
                    name="Datedengagement" 
                    value={inputs.Datedengagement || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date Signature de l'avenant</label>
                    <input type="date" 
                    name="DateSignaturedelavenant" 
                    value={inputs.DateSignaturedelavenant || "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">N de l'ODS</label>
                    <input type="text" 
                    name="NdelODS" 
                    value={inputs.NdelODS || "null"} 
                    onChange={handleChange}
                    className="form-control" placeholder="N de l'ODS" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date de l'ODS</label>
                    <input type="date" 
                    name="DatedelODS" 
                    value={inputs.DatedelODS|| "2022-01-17"} 
                    onChange={handleChange}
                    className="form-control" data-toggle="flatpicker" placeholder="October 9, 2019" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="project-overview" className="form-label">Observation</label>
                    <textarea className="form-control" 
                    name="Observation" 
                    value={inputs.Observation || "null"} 
                    onChange={handleChange}
                    rows={5} placeholder="entrez votre observation" defaultValue={""} />
                  </div>
                </div> {/* end col*/}
              </div>
              {/* end row */}
             
            </div> 
            </form>
            </fieldset>
          </div> {/* end card*/}
        </div> {/* end col*/}
      </div>}
      {loading && <Loading />}
     
      <div className="col-xl-12" id="upload-ccharge">
       
        <div className="col-12 text-center">
  <div className="mt-3">
    <h5 className="mb-2">Fichiers</h5>
    <div className="row mx-n1 g-0">
    <FilesList 
      files={showfiles}
      />
    </div> 
  </div>
</div>

        
      </div>
      
       
        </div> {/* content */}
      </div>
    </div>
    )
}



export default AvDetail
