export const SearchM = `
query MSearch($key: String){MSearch(key:$key){
  id
  NetIntituledelOperation
  Intituleduprojet
  NduLot
  IntituleduLot
  DatedeNotification
  Datedinscription
  APInitiale
  APActuelle
  
  MontantenTTC
  Delai
  Cahierdescharges
  NetVisaduCahierdesCharges
  Choixdeloffre
  Ndeprocedure
  Typedeprocedure
  DatedePublication
  JournaldePublication
  DureedePreparationdesOffres
  NdePVdOuverture
  DatedePVdOuverture
  NdePVdevaluation
  DatedePVdevaluation
  DatedAttribution
  journaldAttribution
  DatedepotCMW
  Rapporteur
  Datedeprogrammation
  NdeVisaCMW
  DatedeVisaCMW
  Ndengagemen
  Datedengagemen
  DateSignaturedeMarche
  NdelODS
  DatedelODS
  Observation
 author{
 name
 email
    }
    ccharge {
      id
      NetIntituledelOperation
    }
    entreprise {
      id
      Entreprise
     
    }  
    created_at
    updated_at
}
}`;

export const Tmarche = `
{
  marches(first: 10, page: 1) {
    paginatorInfo {
     
 total
    }
    
  }
}
`;

export const createMarche = `
mutation 
createMarche(
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $DatedeNotification: String,
  $Datedinscription: String,
  $APInitiale: String,
  $APActuelle: String,
  $EntrepriseM: String,
  $MontantenTTC: String,
  $Delai: String,
  $Cahierdescharges: String,
  $NetVisaduCahierdesCharges: String,
  $Choixdeloffre: String,
  $Ndeprocedure: String,
  $Typedeprocedure: String,
  $DatedePublication: String,
  $JournaldePublication: String,
  $DureedePreparationdesOffres: String,
  $NdePVdOuverture: String,
  $DatedePVdOuverture: String,
  $NdePVdevaluation: String,
  $DatedePVdevaluation: String,
  $DatedAttribution: String,
  $journaldAttribution: String,
  $DatedepotCMW: String,
  $Rapporteur: String,
  $Datedeprogrammation: String,
  $NdeVisaCMW: String,
  $DatedeVisaCMW: String,
  $Ndengagemen: String,
  $Datedengagemen: String,
  $DateSignaturedeMarche: String,
  $NdelODS: String,
  $DatedelODS: String,
  $Observation: String,
  $author_id: String,
  $ccharge_id: String ) {
    createMarche(
 NetIntituledelOperation: $NetIntituledelOperation
 Intituleduprojet:  $Intituleduprojet
 NduLot:  $NduLot
 IntituleduLot:  $IntituleduLot
 DatedeNotification:  $DatedeNotification
 Datedinscription:  $Datedinscription
 APInitiale:  $APInitiale
 APActuelle:  $APActuelle
 EntrepriseM:  $EntrepriseM
 MontantenTTC:  $MontantenTTC
 Delai:  $Delai
 Cahierdescharges:  $Cahierdescharges
 NetVisaduCahierdesCharges:  $NetVisaduCahierdesCharges
 Choixdeloffre:  $Choixdeloffre
 Ndeprocedure:  $Ndeprocedure
 Typedeprocedure:  $Typedeprocedure
 DatedePublication:  $DatedePublication
 JournaldePublication:  $JournaldePublication
 DureedePreparationdesOffres:  $DureedePreparationdesOffres
 NdePVdOuverture:  $NdePVdOuverture
 DatedePVdOuverture:  $DatedePVdOuverture
 NdePVdevaluation:  $NdePVdevaluation
 DatedePVdevaluation:  $DatedePVdevaluation
 DatedAttribution:  $DatedAttribution
 journaldAttribution:  $journaldAttribution
 DatedepotCMW:  $DatedepotCMW
 Rapporteur:  $Rapporteur
 Datedeprogrammation:  $Datedeprogrammation
 NdeVisaCMW:  $NdeVisaCMW
 DatedeVisaCMW:  $DatedeVisaCMW
 Ndengagemen:  $Ndengagemen
 Datedengagemen:  $Datedengagemen
 DateSignaturedeMarche:  $DateSignaturedeMarche
 NdelODS:  $NdelODS
 DatedelODS:  $DatedelODS
 Observation:  $Observation
 author_id:  $author_id
 ccharge_id:  $ccharge_id
  ){
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    NetVisaduCahierdesCharges
    Choixdeloffre
    Ndeprocedure
    Typedeprocedure
    DatedePublication
    JournaldePublication
    DureedePreparationdesOffres
    NdePVdOuverture
    DatedePVdOuverture
    NdePVdevaluation
    DatedePVdevaluation
    DatedAttribution
    journaldAttribution
    DatedepotCMW
    Rapporteur
    Datedeprogrammation
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagemen
    Datedengagemen
    DateSignaturedeMarche
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
     
    }
    ccharge {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APactuelle
      Cahierdescharges
      DatedepotCMW
      DatedeprogrammationCMW
      Rapporteur
      NdeVisaCMW
      DatedeVisaCMW
      typedepublication
      Ndepublication
      Datedepublication
      JournaldePublication
      DatedePublicationsurJournal
      Dureedepreparationdesoffres
      Ndouverture
      Datedouverture
      Ndevaluationdesoffres
      Datedevaluationdesoffres
      Ndelettredecommande
      Datedelettredecommande
      Datedattribution
      Observation
      
      created_at
      updated_at
    }
    avenants {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      APInitiale
      APActuelle
      EntrepriseM
      MontantduMarcheenTTC
      DelaiduMarche
      typedeMarche
      typedAvenant
      MontantdelavenantenTTC
      NouveauMontantduMarcheenTTC
      NouveauDelai
      NetVisadeMarche
      NdODSdemarrage
      DatedODSdemarrage
      NdODSdarret
      DatedODSdarret
      NdODSdereprise
      DatedODSdereprise
      PVdereceptionprovisoire
      DatedepotCMW
      DatedeprogrammationCMW
      NdeVisaCMW
      DatedeVisaCMW
      Ndengagement
      Datedengagement
      DateSignaturedelavenant
      NdelODS
      DatedelODS
      Observation
      created_at
      updated_at
    }
    created_at
    updated_at
  }
}
`;
export const updateMarche = `
mutation 
updateMarche(
  $id: String,
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $DatedeNotification: String,
  $Datedinscription: String,
  $APInitiale: String,
  $APActuelle: String,
  $EntrepriseM: String,
  $MontantenTTC: String,
  $Delai: String,
  $Cahierdescharges: String,
  $NetVisaduCahierdesCharges: String,
  $Choixdeloffre: String,
  $Ndeprocedure: String,
  $Typedeprocedure: String,
  $DatedePublication: String,
  $JournaldePublication: String,
  $DureedePreparationdesOffres: String,
  $NdePVdOuverture: String,
  $DatedePVdOuverture: String,
  $NdePVdevaluation: String,
  $DatedePVdevaluation: String,
  $DatedAttribution: String,
  $journaldAttribution: String,
  $DatedepotCMW: String,
  $Rapporteur: String,
  $Datedeprogrammation: String,
  $NdeVisaCMW: String,
  $DatedeVisaCMW: String,
  $Ndengagemen: String,
  $Datedengagemen: String,
  $DateSignaturedeMarche: String,
  $NdelODS: String,
  $DatedelODS: String,
  $Observation: String,
  $author_id: String,
  $ccharge_id: String ) {
    updateMarche(
 id: $id     
 NetIntituledelOperation: $NetIntituledelOperation
 Intituleduprojet:  $Intituleduprojet
 NduLot:  $NduLot
 IntituleduLot:  $IntituleduLot
 DatedeNotification:  $DatedeNotification
 Datedinscription:  $Datedinscription
 APInitiale:  $APInitiale
 APActuelle:  $APActuelle
 EntrepriseM:  $EntrepriseM
 MontantenTTC:  $MontantenTTC
 Delai:  $Delai
 Cahierdescharges:  $Cahierdescharges
 NetVisaduCahierdesCharges:  $NetVisaduCahierdesCharges
 Choixdeloffre:  $Choixdeloffre
 Ndeprocedure:  $Ndeprocedure
 Typedeprocedure:  $Typedeprocedure
 DatedePublication:  $DatedePublication
 JournaldePublication:  $JournaldePublication
 DureedePreparationdesOffres:  $DureedePreparationdesOffres
 NdePVdOuverture:  $NdePVdOuverture
 DatedePVdOuverture:  $DatedePVdOuverture
 NdePVdevaluation:  $NdePVdevaluation
 DatedePVdevaluation:  $DatedePVdevaluation
 DatedAttribution:  $DatedAttribution
 journaldAttribution:  $journaldAttribution
 DatedepotCMW:  $DatedepotCMW
 Rapporteur:  $Rapporteur
 Datedeprogrammation:  $Datedeprogrammation
 NdeVisaCMW:  $NdeVisaCMW
 DatedeVisaCMW:  $DatedeVisaCMW
 Ndengagemen:  $Ndengagemen
 Datedengagemen:  $Datedengagemen
 DateSignaturedeMarche:  $DateSignaturedeMarche
 NdelODS:  $NdelODS
 DatedelODS:  $DatedelODS
 Observation:  $Observation
 author_id:  $author_id
 ccharge_id:  $ccharge_id
  ){
    id
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    NetVisaduCahierdesCharges
    Choixdeloffre
    Ndeprocedure
    Typedeprocedure
    DatedePublication
    JournaldePublication
    DureedePreparationdesOffres
    NdePVdOuverture
    DatedePVdOuverture
    NdePVdevaluation
    DatedePVdevaluation
    DatedAttribution
    journaldAttribution
    DatedepotCMW
    Rapporteur
    Datedeprogrammation
    NdeVisaCMW
    DatedeVisaCMW
    Ndengagemen
    Datedengagemen
    DateSignaturedeMarche
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
     
    }
    ccharge {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APactuelle
      Cahierdescharges
      DatedepotCMW
      DatedeprogrammationCMW
      Rapporteur
      NdeVisaCMW
      DatedeVisaCMW
      typedepublication
      Ndepublication
      Datedepublication
      JournaldePublication
      DatedePublicationsurJournal
      Dureedepreparationdesoffres
      Ndouverture
      Datedouverture
      Ndevaluationdesoffres
      Datedevaluationdesoffres
      Ndelettredecommande
      Datedelettredecommande
      Datedattribution
      Observation
      
      created_at
      updated_at
    }
    avenants {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      APInitiale
      APActuelle
      EntrepriseM
      MontantduMarcheenTTC
      DelaiduMarche
      typedeMarche
      typedAvenant
      MontantdelavenantenTTC
      NouveauMontantduMarcheenTTC
      NouveauDelai
      NetVisadeMarche
      NdODSdemarrage
      DatedODSdemarrage
      NdODSdarret
      DatedODSdarret
      NdODSdereprise
      DatedODSdereprise
      PVdereceptionprovisoire
      DatedepotCMW
      DatedeprogrammationCMW
      NdeVisaCMW
      DatedeVisaCMW
      Ndengagement
      Datedengagement
      DateSignaturedelavenant
      NdelODS
      DatedelODS
      Observation
      created_at
      updated_at
    }
    created_at
    updated_at
  }
}

`;
export const deleteMarche = `
mutation 
deleteMarche(
  $id: String!,) {
    deleteMarche(id: $id){
    id
    NetIntituledelOperation
    }
}
`;
