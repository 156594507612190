import React, {useState} from 'react'

import { Redirect } from "react-router-dom";



const Login = (data) => {
  const [psw, setPsw] = useState("");
  const [email, setEmail] = useState("");
  

 

  const handleSubmit = (event) => {
    event.preventDefault();
  
  
     data.onFormLogin(email,psw)
  }
  

  
    return(
      <div className="loading authentication-bg authentication-bg-pattern bg-primary border-primary border shadow">
      <div className="account-pages mt-5 mb-5 ">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-4">
        <div className="card bg-pattern">
          <div className="card-body p-4 bg-info border-info border shadow">
            <div className="text-center w-75 m-auto">
              <div className="auth-logo">
               
              </div>
              <p className="text-muted mb-4 mt-3">Entrez votre EMAIL et votre MOT DE PASSE pour accéder aux GGMP.</p>
            </div>
            <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="emailaddress" className="form-label">
                          Email address
                        </label>
                        <input
                          type="email"
                          aria-describedby="email"
                          className="form-control"
                          placeholder="example@ggmp.com"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setPsw(e.target.value)}
                            value={psw}
                            placeholder="mote de pass"
                          />

                          <div
                            className="input-group-text"
                            data-password="false"
                          >
                            <span className="password-eye" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox-signin"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox-signin"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="text-center d-grid">
                      {!data.loading &&
                        <button className="btn btn-primary" type="submit">
                          Log In
                        </button>}
                        {data.loading &&
                        <button type="button" className="btn btn-warning disabled">Loading</button>}
                      </div>
                    </form>
          </div> {/* end card-body */}
        </div>
      </div> {/* end col */}
    </div>
    {/* end row */}
  </div>
  {/* end container */}
</div>
</div>
    )
}

export default Login
