export const SearchCo = `
query CoSearch($key: String){CoSearch(key:$key){
  id
    NdelaConvention
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    Choixdeloffre
    Nprocedure
    typedeprocedure
    DatedePublication
    DureedePreparationdesOffres
    NPVdOuverture
    DatedePVdOuverture
    NPVdevaluation
    DatedePVdevaluation
    DatedAttribution
    JournaleAttribution
    DatedepotauCF
    Ndengagement
    Datedengagement
    DateSignaturedelaconvention
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
    }
    ccharge {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APactuelle
      Cahierdescharges
      DatedepotCMW
      DatedeprogrammationCMW
      Rapporteur
      NdeVisaCMW
      DatedeVisaCMW
      typedepublication
      Ndepublication
      Datedepublication
      JournaldePublication
      DatedePublicationsurJournal
      Dureedepreparationdesoffres
      Ndouverture
      Datedouverture
      Ndevaluationdesoffres
      Datedevaluationdesoffres
      Ndelettredecommande
      Datedelettredecommande
      Datedattribution
      Observation
     
      created_at
      updated_at
    }
    avenants {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      APInitiale
      APActuelle
      EntrepriseM
      MontantduMarcheenTTC
      DelaiduMarche
      typedeMarche
      typedAvenant
      MontantdelavenantenTTC
      NouveauMontantduMarcheenTTC
      NouveauDelai
      NetVisadeMarche
      NdODSdemarrage
      DatedODSdemarrage
      NdODSdarret
      DatedODSdarret
      NdODSdereprise
      DatedODSdereprise
      PVdereceptionprovisoire
      DatedepotCMW
      DatedeprogrammationCMW
      NdeVisaCMW
      DatedeVisaCMW
      Ndengagement
      Datedengagement
      DateSignaturedelavenant
      NdelODS
      DatedelODS
      Observation
      created_at
      updated_at
    }
    entreprise {
      id
      Entreprise
      tel
      NIS
      NIF
      created_at
      updated_at
    }
    
    created_at
    updated_at
}
}`;

export const Tconvention = `
{
  conventions(first: 10, page: 1) {
    paginatorInfo {
     
      total
    }
    
  }
}
`;

export const createConvention = `
mutation 
createConvention(
  $NdelaConvention: String,
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $DatedeNotification: String,
  $Datedinscription: String,
  $APInitiale: String,
  $APActuelle: String,
  $EntrepriseM: String,
  $MontantenTTC: String,
  $Delai: String,
  $Cahierdescharges: String,
  $Choixdeloffre: String,
  $Nprocedure: String,
  $typedeprocedure: String,
  $DatedePublication: String,
  $DureedePreparationdesOffres: String,
  $NPVdOuverture: String,
  $DatedePVdOuverture: String,
  $NPVdevaluation: String,
  $DatedePVdevaluation: String,
  $DatedAttribution: String,
  $JournaleAttribution: String,
  $DatedepotauCF: String,
  $Ndengagement: String,
  $Datedengagement: String,
  $DateSignaturedelaconvention: String,
  $NdelODS: String,
  $DatedelODS: String,
  $Observation: String,
  $author_id: String,
  $ccharge_id: String,) {
    createConvention(
    NdelaConvention: $NdelaConvention
    NetIntituledelOperation: $NetIntituledelOperation
    Intituleduprojet: $Intituleduprojet
    NduLot: $NduLot
    IntituleduLot: $IntituleduLot
    DatedeNotification: $DatedeNotification
    Datedinscription: $Datedinscription
    APInitiale: $APInitiale
    APActuelle: $APActuelle
    EntrepriseM: $EntrepriseM
    MontantenTTC: $MontantenTTC
    Delai: $Delai
    Cahierdescharges: $Cahierdescharges
    Choixdeloffre: $Choixdeloffre
    Nprocedure: $Nprocedure
    typedeprocedure: $typedeprocedure
    DatedePublication: $DatedePublication
    DureedePreparationdesOffres: $DureedePreparationdesOffres
    NPVdOuverture: $NPVdOuverture
    DatedePVdOuverture: $DatedePVdOuverture
    NPVdevaluation: $NPVdevaluation
    DatedePVdevaluation: $DatedePVdevaluation
    DatedAttribution: $DatedAttribution
    JournaleAttribution: $JournaleAttribution
    DatedepotauCF: $DatedepotauCF
    Ndengagement: $Ndengagement
    Datedengagement: $Datedengagement
    DateSignaturedelaconvention: $DateSignaturedelaconvention
    NdelODS: $NdelODS
    DatedelODS: $DatedelODS
    Observation: $Observation
    author_id: $author_id
    ccharge_id: $ccharge_id
  ){
    id
    NdelaConvention
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    Choixdeloffre
    Nprocedure
    typedeprocedure
    DatedePublication
    DureedePreparationdesOffres
    NPVdOuverture
    DatedePVdOuverture
    NPVdevaluation
    DatedePVdevaluation
    DatedAttribution
    JournaleAttribution
    DatedepotauCF
    Ndengagement
    Datedengagement
    DateSignaturedelaconvention
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
      
    }
    ccharge {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APactuelle
      Cahierdescharges
      DatedepotCMW
      DatedeprogrammationCMW
      Rapporteur
      NdeVisaCMW
      DatedeVisaCMW
      typedepublication
      Ndepublication
      Datedepublication
      JournaldePublication
      DatedePublicationsurJournal
      Dureedepreparationdesoffres
      Ndouverture
      Datedouverture
      Ndevaluationdesoffres
      Datedevaluationdesoffres
      Ndelettredecommande
      Datedelettredecommande
      Datedattribution
      Observation
      
      created_at
      updated_at
    }
    entreprise {
      id
      Entreprise
      tel
      NIS
      NIF
     
      created_at
      updated_at
    }
    created_at
    updated_at
  }
}
`;
export const updateConvention = `
mutation 
updateConvention(
  $id: String,
  $NdelaConvention: String,
  $NetIntituledelOperation: String,
  $Intituleduprojet: String,
  $NduLot: String,
  $IntituleduLot: String,
  $DatedeNotification: String,
  $Datedinscription: String,
  $APInitiale: String,
  $APActuelle: String,
  $EntrepriseM: String,
  $MontantenTTC: String,
  $Delai: String,
  $Cahierdescharges: String,
  $Choixdeloffre: String,
  $Nprocedure: String,
  $typedeprocedure: String,
  $DatedePublication: String,
  $DureedePreparationdesOffres: String,
  $NPVdOuverture: String,
  $DatedePVdOuverture: String,
  $NPVdevaluation: String,
  $DatedePVdevaluation: String,
  $DatedAttribution: String,
  $JournaleAttribution: String,
  $DatedepotauCF: String,
  $Ndengagement: String,
  $Datedengagement: String,
  $DateSignaturedelaconvention: String,
  $NdelODS: String,
  $DatedelODS: String,
  $Observation: String,
  $author_id: String,
  $ccharge_id: String,) {
    updateConvention(
        id: $id
        NdelaConvention: $NdelaConvention
    NetIntituledelOperation: $NetIntituledelOperation
    Intituleduprojet: $Intituleduprojet
    NduLot: $NduLot
    IntituleduLot: $IntituleduLot
    DatedeNotification: $DatedeNotification
    Datedinscription: $Datedinscription
    APInitiale: $APInitiale
    APActuelle: $APActuelle
    EntrepriseM: $EntrepriseM
    MontantenTTC: $MontantenTTC
    Delai: $Delai
    Cahierdescharges: $Cahierdescharges
    Choixdeloffre: $Choixdeloffre
    Nprocedure: $Nprocedure
    typedeprocedure: $typedeprocedure
    DatedePublication: $DatedePublication
    DureedePreparationdesOffres: $DureedePreparationdesOffres
    NPVdOuverture: $NPVdOuverture
    DatedePVdOuverture: $DatedePVdOuverture
    NPVdevaluation: $NPVdevaluation
    DatedePVdevaluation: $DatedePVdevaluation
    DatedAttribution: $DatedAttribution
    JournaleAttribution: $JournaleAttribution
    DatedepotauCF: $DatedepotauCF
    Ndengagement: $Ndengagement
    Datedengagement: $Datedengagement
    DateSignaturedelaconvention: $DateSignaturedelaconvention
    NdelODS: $NdelODS
    DatedelODS: $DatedelODS
    Observation: $Observation
    author_id: $author_id
    ccharge_id: $ccharge_id
  ){
    id
    NdelaConvention
    NetIntituledelOperation
    Intituleduprojet
    NduLot
    IntituleduLot
    DatedeNotification
    Datedinscription
    APInitiale
    APActuelle
    EntrepriseM
    MontantenTTC
    Delai
    Cahierdescharges
    Choixdeloffre
    Nprocedure
    typedeprocedure
    DatedePublication
    DureedePreparationdesOffres
    NPVdOuverture
    DatedePVdOuverture
    NPVdevaluation
    DatedePVdevaluation
    DatedAttribution
    JournaleAttribution
    DatedepotauCF
    Ndengagement
    Datedengagement
    DateSignaturedelaconvention
    NdelODS
    DatedelODS
    Observation
    author {
      id
      name
      status
      email
      created_at
      updated_at
      
    }
    ccharge {
      id
      NetIntituledelOperation
      Intituleduprojet
      NduLot
      IntituleduLot
      DatedeNotification
      Datedinscription
      APInitiale
      APactuelle
      Cahierdescharges
      DatedepotCMW
      DatedeprogrammationCMW
      Rapporteur
      NdeVisaCMW
      DatedeVisaCMW
      typedepublication
      Ndepublication
      Datedepublication
      JournaldePublication
      DatedePublicationsurJournal
      Dureedepreparationdesoffres
      Ndouverture
      Datedouverture
      Ndevaluationdesoffres
      Datedevaluationdesoffres
      Ndelettredecommande
      Datedelettredecommande
      Datedattribution
      Observation
      
      created_at
      updated_at
    }
    entreprise {
      id
      Entreprise
      tel
      NIS
      NIF
     
      created_at
      updated_at
    }
    created_at
    updated_at
  }
}
`;
export const deletConvention = `
mutation 
deleteConvention(
  $id: String!,) {
    deleteConvention(id: $id){
    id
    NdelaConvention
    }
}
`;
