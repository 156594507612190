import React,{useState} from 'react'
import { createUser} from '../../libs/user'
import { API } from '../../libs/costants'
import "react-notifications-component/dist/theme.css";
import { store } from 'react-notifications-component';

const UsCreate = () => {
  const [inputs, setInputs] = useState({});
  const [files, setFiles] = useState([]);
  const [postid, setPostid] = useState("");
  const [showup, setShowup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showfiles, setShowFiles] = useState([]);
  const notify = (msg,type)=>store.addNotification({
    title: "succès",
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  }); 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    
    //console.log(inputs)
    create(inputs)

  }
  const create = async(post)=>{
    try {

  
      const graphqlQuery = {
        query: createUser,
        variables: {
          name: post.name,
          email: post.email,
          status: post.status,
          password: post.password
        },
      };
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ggmpToken"),
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          console.log(data)
          notify("Creation Utilisateur "+ data.data.createUser.name +" avec succés","success")
          setPostid(data.data.createUser.id)
          setShowup(true)
        })
        .catch((error) => {
          notify("error : VERRIFIER VOTRE CONNECTION   " + error,"danger")
        })

        
    } catch {
         
    }

    

    

  }
 
    return (
        <div className="content-page">
  <div className="content">
    {/* Start Content*/}
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">GGMP</a></li>
                <li className="breadcrumb-item"><a href="javascript: void(0);">Utilisateur</a></li>
                <li className="breadcrumb-item active">Creation</li>
              </ol>
            </div>
            <h4 className="page-title">Creation d' Utilisateur</h4>
          </div>
        </div>
      </div>     
      {/* end page title */} 
      <div className="row">
        <div className="col-12">
        <form onSubmit={handleSubmit} >
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">Email</label>
                    <input type="text" 
                    name="email" 
                    value={inputs.email|| "null"}
                    onChange={handleChange} 
                    className="form-control" placeholder="Email" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">Nome</label>
                    <input type="text" 
                    name="name" 
                    value={inputs.name|| "null"}
                    onChange={handleChange}
                    className="form-control" placeholder="Nome" />
                  </div>
                 
                  <div className="mb-3">
                    <label htmlFor="project-overview" className="form-label">Status</label>
                    <select 
                    name="status" 
                    value={"1" || inputs.status } 
                    onChange={handleChange}
                    className="form-control" data-toggle="select2" data-width="100%">
                      <option value="user">Utilisateur</option>
                      <option value="admin">Administrateur</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="projectname3" className="form-label">Mot de passe</label>
                    <input type="text" 
                    name="password" 
                    value={inputs.password|| "null"}
                    onChange={handleChange}
                    className="form-control" placeholder="Mot de passe" />
                  </div>
                  <div className="row mt-3">
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-success waves-effect waves-light m-1" ><i className="fe-check-circle me-1" /> Créer</button>
               </div>
              </div>
                </div> {/* end col*/}
              </div>
            </div> {/* end card-body */}
          </div> {/* end card*/}
          </form>
        </div> {/* end col*/}
      </div>
      {/* end row*/}
      {/* container */}
    </div> {/* container */}
  </div> {/* content */}
</div>

    )
}

export default UsCreate
